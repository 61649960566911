import { AEngine } from "../../core/AEngine.js";
import { AUserManualService } from "../../services/AUserManualService.js";
import { AError } from "../AError.js";
import { AWindowBase } from "../windows/AWindowBase.js";
export class AUserManualBasicWindow extends AWindowBase {
    constructor(opt) {
        super();
        this.opt = opt;
        this.userManualService = AEngine.get(AUserManualService);
    }
    async configureToolbar() {
        return null;
    }
    async show() {
        let id = idAllocatorService.getNextId({ prefix: 'win-table-' });
        await super.generate({
            content: ( /*html*/`
        <div id="${id}" class="fw fh" style="padding: 0.4rem">
          <div class="columns fh">
            <div class="column col-12 fh" style="overflow-y: auto">
              <div class="markdown-renderer"></div>
            </div>
          </div>
        </div>
      `),
            size: { width: 600, height: 'inherit' },
            minSize: { width: 320, height: 180 },
            alignTo: 'right',
            lifeCycle: 'PAGE'
        });
        this.title = this.opt.showTitle ? this.opt.manual.Title ?? '' : '';
        Loading.waitForPromises(this.renderPreview(this.opt.manual)).catch(AError.handle);
        return window;
    }
    async renderPreview(item) {
        let $renderer = this.$internal.find('.markdown-renderer');
        await this.userManualService.prefetchImages(item.Body);
        await this.userManualService.renderMarkdown(item.Body).then((val) => {
            $renderer.html(val);
        });
    }
    invokeClose() {
        this.handleClose();
    }
    handleClose() {
        this.$internal.remove();
        this.handler.destruct();
    }
}
