import { AError } from "../classes/AError.js";
import { isUserACI } from "../utils/tools.js";
import { EVENTS } from "./AEventService.js";
export class AAdminAlertsService {
    constructor() {
        this.count = 0;
    }
    get $statusBar() {
        return $('.statusbar span');
    }
    get $alertCount() {
        return $('.alert-count');
    }
    async checkAlerts() {
        if (!isUserACI()) {
            return;
        }
        const { $statusBar } = this;
        if ($statusBar.length === 0) {
            return console.warn(`AAdminAlerts Couldn't find $('.statusbar') ...`);
        }
        this.text = await Translate.get('Admin Alerts');
        Events.hardwire(EVENTS.DESTRUCT, () => this.refresh());
    }
    update(count) {
        if (this.count === 0) {
            this.deleteBar();
        }
        this.$alertCount.text(count);
        if (count === 0) {
            this.$alertCount.addClass('hidden');
        }
        else {
            this.$alertCount.removeClass('hidden');
        }
    }
    deleteBar() {
        if (this.$ele) {
            this.$ele.remove();
            delete this.$ele;
        }
    }
    createBar(text, count) {
        const $ele = $(`
            <div id="AdminBar" class="hide-sm">
                <a href="/#!/aci/admin_alerts.html" class="AdminAlerts" id="AdminAlerts">
                    <b>${count}</b>
                    <span>${text}</span>
                </a>
            </div>
        `);
        const { $statusBar } = this;
        $statusBar.after($ele);
        this.$ele = $ele;
    }
    fetch() {
        return requestService.query({
            Query: (`
                SELECT COUNT(*) FROM admin_alerts WHERE Checked=0
            `)
        }).then((response) => {
            if (!response.Rows) {
                return 0;
            }
            if (!response.Rows.length) {
                return 0;
            }
            if (!response.Rows[0].length) {
                return 0;
            }
            return parseInt(response.Rows[0][0]);
        });
    }
    async createAlert({ GroupId, Importance, Message }) {
        try {
            if (GroupId) {
                GroupId = GroupId.substring(0, 128);
            }
            Message = Message.substring(0, 1024);
            await requestService.query({
                Query: (`REPLACE INTO admin_alerts (GroupId, Importance, Message, Checked, CreatedAt, id_mr) VALUES (:GroupId, :Importance, :Message, 0, CURRENT_TIMESTAMP, :IDMR)`),
                Params: { GroupId, Importance, Message, IDMR: routeService.meta?.menuItem?.id_mr || null }
            });
            this.update(this.count);
            return true;
        }
        catch (err) {
            console.error(err);
            return false;
        }
    }
    createErrorAlert(err, groupId) {
        const cleanStack = AError.simplifyError(err);
        return this.createAlert({
            GroupId: groupId || cleanStack.substring(0, 128),
            Importance: 1,
            Message: cleanStack
        });
    }
    async refresh() {
        this.count = await this.fetch();
        this.update(this.count);
    }
}
