import { AEngine, sleep } from "../core/AEngine.js";
import { EVENTS } from "../services/AEventService.js";
import { AUnitTest } from "../core/AUnitTest.js";
import { ATableFormatter } from "../core/form/table/ATableFormatter.js";
import { initTableAccordions } from "../utils/table_accordions.js";
export class APageVisitorUnit extends AUnitTest {
    constructor({ period }) {
        super({ period });
        this.skipPages = [
            '/authenticator.html',
            '/legacy-operations.html',
            '/login.html',
            '/profile.html',
            '/reload.html'
        ];
        super.addSetup(() => this.setup());
        super.addTest(() => this.testAllPages());
    }
    async setup(...args) {
        // this.loopService = AEngine.get(AAutoRefreshService)
        this.baseMetaData = {};
        this.metadata = {};
        this.pageNr = 0;
        this.hrefs = menuService.availableUrls // Object.keys(menuService.menuFlat)
            .filter(href => !href.startsWith('/test/'))
            .filter(href => !this.skipPages.includes(href));
        this.hrefs.map(href => {
            this.baseMetaData[href] = {};
        });
        this.w = await this.createDialog();
    }
    async buildTable(opt) {
        const tb = await purgatoryService.createSingleTable({
            tableId: idAllocatorService.getNextId({ prefix: 'dyn-table-' }),
            data: opt.data,
            tableFormatter: new ATableFormatter({ "*": { type: 'DYNAMIC', expanded: true } }),
            greyOutFields: true
        });
        return tb.build({ classes: ['table-accordion-lowercase'], minWidth: '500px' });
    }
    async createDialog(parent = 'body') {
        const window = await purgatoryService.showInfoWindowSingleCustom({
            nest: parent,
            parent: parent,
            table: await this.buildTable({ data: this.baseMetaData }),
            options: { height: '500px' },
            hardwire: true,
        });
        return window;
    }
    async updateWindow() {
        const { $window } = this.w;
        const html = await this.buildTable({ data: Object.assign({}, this.baseMetaData, this.metadata) });
        let scrollTop = $window.find('table').scrollTop() ?? 0;
        $window.find('table').replaceWith(html);
        $window.find('table').scrollTop(scrollTop);
        initTableAccordions($window);
    }
    async testAllPages() {
        for (let href of this.hrefs) {
            try {
                if (this.error !== null) {
                    throw this.error;
                }
                const metadata = await this.testPage(href);
                this.metadata[href] = metadata;
                this.pageNr++;
            }
            catch (err) {
                console.error(err);
                this.metadata[href] = { err: err instanceof Error ? err.message : err };
                throw err;
            }
            finally {
                this.updateWindow();
            }
        }
        console.log('Printing csv...');
        console.log(`"url", "time in ms"\n` +
            Object.keys(this.metadata).map(key => `"${key}", ${this.metadata[key].loadTime || -1}`).join('\n'));
    }
    async testPage(href) {
        href = href.startsWith('/#!') ? href : '/#!' + href;
        AEngine.log(`%cAPageVisitorUnit%n Testing Page %c${this.pageNr}%n of %c${this.hrefs.length}`);
        // eventService.logging = true`
        const waitOnce = new Promise((resolve) => {
            Events.h_once(EVENTS.PAGE_INITIALIZED, () => {
                AEngine.log(`PAGE_INITIALIZED`);
                resolve();
            });
        });
        window.location.href = href;
        await waitOnce;
        await sleep(100);
        const hasFilters = $('#AjaxContent #Filters').length > 0;
        const pageMetaData = { hasFilters };
        if (hasFilters) {
            this.selectTimeFrame();
            pageMetaData.initType = PageScript.init?.constructor?.name || null;
            pageMetaData.refreshType = PageScript.refresh?.constructor?.name || null;
            if (PageScript.refresh) {
                let d = Date.now();
                pageMetaData.refreshResult = await Promise.resolve().then(() => PageScript.refresh());
                const loadTime = Date.now() - d;
                pageMetaData.loadTime = loadTime + 'ms';
                AEngine.log(`Refresh took %c${loadTime}ms`);
                await sleep(500);
            }
        }
        await sleep(500);
        return pageMetaData;
    }
}
