import { sleep } from "../../core/AEngine.js";
import { EVENTS } from "../../services/AEventService.js";
// type APopoverRef = {$ele: JQuery, destroy: () => void}
export class AWindowToolbar {
    // private popovers: (APopoverRef|undefined)[] = []
    constructor(parent, opt) {
        this.parent = parent;
        this.rootItems = opt.map((item) => new AWindowToolbarItem({ ...item, root: true, parent: this }));
    }
    attachTo($win) {
        const $target = $win.find('.toolbar-collection');
        $target.html('');
        $target.append(this.rootItems.map((item, i) => item.generateAsRootItem(`toolbar-menu-${i}`)));
        $target.removeClass('hidden');
    }
}
export class AWindowToolbarItem {
    constructor(opt) {
        this.root = opt.root ?? false;
        this.title = opt.title;
        this.href = opt.href;
        this.action = opt.action;
        this.parent = opt.parent;
        this.enabled = opt.enabled ?? true;
        this.children = (opt?.children ?? []).map(c => (c !== null) ? new AWindowToolbarItem({ ...c, parent: this }) : null);
    }
    get hasAction() {
        return this.action !== undefined;
    }
    get hasChildren() {
        return this.children.length > 0;
    }
    get hasValidHref() {
        return this.href !== undefined && this.href !== '#';
    }
    generateAsMenuItem() {
        const attrs = [`href="${this.href ?? '#'}"`, this.href ? `target="blank"` : '', this.enabled ? '' : `disabled="disabled"`].filter(v => v != '').join(' ');
        const icon = this.hasChildren ? `<i class="fa-solid fa-chevron-right fa-xs"></i>` : '';
        const $btn = $(/*html*/ `<a ${attrs}><div class="m-item noselect">${this.title} ${icon}</div></a>`)
            .on('click', (e) => (!this.hasValidHref) ? e.preventDefault() : undefined);
        this.$btn = this.initMenuItem($btn);
        return this.$btn;
    }
    generateAsRootItem(id) {
        const $btn = $(/*html*/ `<span id="${id}" class="awin-toolbar-item noselect">${this.title}</span>`);
        this.$btn = this.initMenuItem($btn);
        return this.$btn;
    }
    initMenuItem($btn) {
        $btn.on('mousedown', (e) => {
            if (e.button !== 0)
                return;
            const { hasValidHref, hasAction, hasChildren } = this;
            console.log({ hasValidHref, hasAction, hasChildren });
            if (this.hasValidHref) {
                sleep(80).then(() => this.closeContextMenu(true));
                return;
            }
            if (this.hasAction) {
                this.action(this);
                // var $pp = $btn.closest('.popover-open')
                // while ($pp.length > 0) {
                //   const ppid = $pp.attr('id')
                //   let $tmp = $(`[childpid="${ppid}"]`)
                //   $tmp.removeAttr('childpid')
                //   $pp.remove()
                //   $pp = $tmp.closest('.popover-open')
                // }
                return this.closeContextMenu(true);
            }
            if (this.hasChildren) {
                if (this.contextMenuId === undefined) {
                    this.openContextMenu();
                    this.initContextMenu();
                }
                else {
                    this.closeContextMenu();
                }
            }
        });
        return $btn;
    }
    openContextMenu() {
        console.log('open', this.title, this);
        const $btn = this.$btn;
        this.contextMenuId = idAllocatorService.getNextId({ prefix: 'popover-' });
        this.$contextMenu = $(/*html*/ `<div id="${this.contextMenuId}" class="popover-menu-experimental"></div>`).appendTo('body');
        this.children.map(c => {
            if (c === null) {
                return $(/*html*/ `<a class="noselect"><div class="m-item noselect"></div></a>`).appendTo(this.$contextMenu);
            }
            return c.generateAsMenuItem().appendTo(this.$contextMenu);
        });
        $btn.attr('childpid', this.contextMenuId);
        const options = { anchor: 'left' };
        let bo = ($btn.offset() || { left: 0, top: 0 });
        let mt = this.root ? 3 : -4;
        this.$contextMenu.css((options.anchor === 'left')
            ? { left: bo.left + (this.root ? 0 : $btn.outerWidth()), top: bo.top + ((this.root ? $btn.outerHeight() : 0)) + mt }
            : { right: innerWidth - bo.left - ($btn.innerWidth() || 0), top: bo.top + ($btn.outerHeight() || 0) + mt });
        this.$contextMenu.addClass('popover-open');
    }
    initContextMenu() {
        sleep(1).then(() => {
            this.closeEventId = Events.hardwire(EVENTS.MOUSE_CLICK, ($clicked) => {
                var childpid = $clicked.attr('childpid');
                var isSamePid = $clicked.is(`[childpid=${this.contextMenuId}]`);
                var popoverPid = $clicked.closest('.popover-open').attr('id') ?? $clicked.attr('childpid');
                console.log({ childpid, isSamePid, popoverPid });
                if (isSamePid || popoverPid === this.contextMenuId) {
                    return;
                }
                this.closeContextMenu();
            });
        });
    }
    closeContextMenu(closeAll = false) {
        console.log('close', this.title, this);
        this.$btn.removeAttr('childpid');
        this.$contextMenu?.remove();
        this.$contextMenu = undefined;
        this.contextMenuId = undefined;
        if (this.closeEventId) {
            Events.off(EVENTS.MOUSE_CLICK, this.closeEventId);
            this.closeEventId = undefined;
        }
        if (closeAll && this.parent && this.parent instanceof AWindowToolbarItem) {
            this.parent.closeContextMenu();
        }
    }
}
