import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_TEXT, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AImageCropper } from "../../classes/AImageCropper.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AUploadedImageOrm } from "../../orm/AUploadedImageOrm.js";
import { ALERTS, ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { toast } from "../../utils/toasts.js";
import { AConvertToGridColumns, AConvertToGridData, AFormatBytes, AShowTable, AUrlEncodedImageFromBase64 } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.orm = new AUploadedImageOrm();
    }
    async init() {
        $('#upload-image').on('click', (e) => this.showUploadForm());
        await this.refresh();
    }
    showUploadForm() {
        const events = Alerts.show({
            title: ALERT_TITLES.None,
            type: ALERTS.Medium,
            content: ( /*HTML*/`
        <input id="input-upload-img" type="file" class="hidden">
        <div class="upl-step-1">
          <button id="image-upload-btn" class="btn btn-primary">
            <i class="fa-regular fa-cloud-arrow-up"></i>
            <span>Upload Image</span>
          </button>
        </div>
        
        <div class="upl-step-2">
          <div class="form-group">
            <label class="form-label" for="AltText">Alt Text</label>
            <input class="form-input" type="text" id="AltText" name="AltText" disabled="disabled">
          </div>
        </div>

        <div class="upl-step-3 hidden">
          <p id="b64"></p>
          <div class="image-preview"><img src="#"></div>
          <div class="image-preview"><img src="#"></div>
          <div class="image-preview"><img src="#"></div>
        </div>
      `),
            buttons: ALERT_BUTTONS.createCancel
        });
        const { $ele } = events;
        const $altText = $ele.find('#AltText');
        const $uploadProxy = $ele.find('#image-upload-btn');
        const $upload = $ele.find('#input-upload-img');
        const $imgArr = $ele.find('.image-preview img').toArray().map(ele => $(ele));
        $uploadProxy.on('click', () => $upload.trigger('click'));
        let images;
        $upload.on('change', async (e) => {
            // Read File
            const files = $upload.prop('files');
            if (files.length == 0) {
                images = undefined;
                $altText.prop('disabled', 'disabled');
                $('.upl-step-1').removeClass('hidden');
                $('.upl-step-3').addClass('hidden');
                return toast({ msg: 'No File Selected!' });
            }
            if (files.length > 1) {
                toast({ msg: 'Multiple file upload is currently not supported!' });
            }
            const loadedFile = files.item(0);
            const imgCropper = new AImageCropper({ file: loadedFile });
            images = await imgCropper.cropToScaled({ renderTo: $imgArr });
            console.log(images);
            $altText.val(loadedFile.name.replace(/\..*/g, ''));
            $altText.removeProp('disabled');
            $altText.removeAttr('disabled');
            $('.upl-step-1').addClass('hidden');
            $('.upl-step-3').removeClass('hidden');
            // const fr = new FileReader()
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                if (!images)
                    return false;
                let name = $altText.val() || null;
                debugger;
                await Loading.waitForPromises(this.orm.create({
                    AltText: name || 'Image 1',
                    LargeImage: images.large.toBase64({ stripPrefix: true }),
                    MediumImage: images.medium.toBase64({ stripPrefix: true }),
                    SmallImage: images.small.toBase64({ stripPrefix: true }),
                    FileSize: images.large.fileSize,
                    MimeType: images.large.mimeType,
                }));
            }
            catch (err) {
                AError.handle(err);
            }
        });
    }
    async refresh() {
        return Loading.waitForPromises(this.orm.fetchAll()).then(async (ares) => {
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                aci: { resizeToFit: true, resizeToFitReverse: true, skipResizeColumns: ['AltText'] },
                columns: AConvertToGridColumns(ares, {
                    ImageId: COLUMN_HIDDEN,
                    SmallImage: {
                        text: '',
                        cellCls: 'np-i',
                        searchable: false,
                        htmlEncode: false,
                        renderer: ({ value, size }) => {
                            size.width = 128;
                            size.height = 128;
                            return `<img width="128px" height="128px" src="${AUrlEncodedImageFromBase64(value)}" />`;
                        }
                    },
                    AltText: {
                        ...COLUMN_TEXT,
                        flex: 1,
                    },
                    FileSize: {
                        htmlEncode: false,
                        renderer: ({ value }) => `${AFormatBytes(value ?? 0)}`
                    },
                    CreatedAt: COLUMN_DATETIME,
                }),
                data: AConvertToGridData(ares, {
                    CreatedAt: DATA_DATETIME,
                }),
            });
            this.grid.on('cellclick', ({ record, column }) => {
                // console.log(record.originalData)
                if (!record || record.id && record.id?.indexOf('group-header') !== -1) {
                    return;
                }
                AEngine.log('clicked', record.data);
            });
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*HTML*/`
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div class="flex-content">
        <div template="${ATemplates.WaitingForInput}"></div>
        <div id="table-bryntum" class="fh hidden"></div>
      </div>
      
      <div class="columns footer aci">
        <div class="column col-2 col-ml-auto">
          <button id="upload-image" class="btn btn-primary col-12">Upload Image</button>
          <button id="export" class="btn btn-primary col-12 hidden" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
