import { ACrypto } from "../classes/ACrypto.js";
export class AUserManualOrm {
    async fetchAll() {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: ( /*sql*/`
        SELECT MarkdownId, Lang, Title, Body, CreatedByUser, CreatedByDisplayName, CreatedAt, Id_Mr
        FROM markdown_pages
        LEFT JOIN user_manual USING (MarkDownId)
        GROUP BY MarkdownId, Lang
        ORDER BY CreatedAt
      `)
        });
        return rows;
    }
    async create(options) {
        await requestService.query({
            Query: ( /*sql*/`
        INSERT INTO markdown_pages (MarkdownId, Lang, Title, Body, CreatedByUser, CreatedByDisplayName)
        VALUES (:MarkdownId, :Lang, :Title, :Body, :CreatedByUser, :CreatedByDisplayName)
        ON DUPLICATE KEY UPDATE Lang=:Lang, Title=:Title, Body=:Body, CreatedByUser=:CreatedByUser, CreatedByDisplayName=:CreatedByDisplayName
      `),
            Params: {
                MarkdownId: options.MarkdownId ?? ACrypto.uuid7String(),
                Lang: options.Lang ?? 'en',
                Title: options.Title,
                Body: options.Body,
                CreatedByUser: _.getUser().User,
                CreatedByDisplayName: _.getUser().UserDisplayName,
            }
        });
        return true;
    }
    async link(id_mr, markdownId) {
        await requestService.query({
            Query: (`INSERT INTO user_manual (Id_MR, MarkdownId) VALUES (:Id_MR, :MarkdownId)`),
            Params: {
                Id_MR: id_mr,
                MarkdownId: markdownId,
            }
        });
    }
    async unlink(options) {
        await requestService.query({
            Query: (`DELETE FROM user_manual WHERE MarkdownId=:MarkdownId`),
            Params: {
                MarkdownId: options.MarkdownId
            }
        });
    }
}
