import { AColor } from "../classes/AColor.js";
import { AColorMapper } from "../classes/AColorMapper.js";
import { AError, ERROR_GROUPS } from "../classes/AError.js";
import { ADetectionState, ADigital, AIllegallyParked, AParkingRight, AVerification } from "../classes/AUnificationTypes.js";
import { AEngine, sleep } from "../core/AEngine.js";
import { DefaultBounds, getCenterAny } from "../utils/maps.js";
import { asyncMapArray, convertObjectToArray, toggleMapFullScreen, toggleMapSearch } from "../utils/tools.js";
import { EVENTS } from "./AEventService.js";
import { AMapOverlayService, MAP_POSITION } from "./AMapOverlayService.js";
import { APrefs } from "./APreferenceService.js";
import { APopoverService } from "./APopoverService.js";
import { ALERTS, ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "./AAlertService.js";
import { AFormInstance } from "../core/form/AFormInstance.js";
import { AIdAllocator } from "../core/allocator/AIdAllocator.js";
import { ALL_GEO_MAPPING, ALL_GEO_TYPES, ALL_MAP_OPTIONS, MAP_OPTIONS, UNLOAD_OPTIONS, densityOptions, mapStyleOptions } from "../core/maps/AMapStructs.js";
import { toast } from "../utils/toasts.js";
import { AGeoUtils } from "../core/maps/AGeoUtils.js";
Object.assign(globalThis, {
    MAP_OPTIONS,
    ALL_MAP_OPTIONS,
});
export class AMapHelperService {
    geoObjectMapper(mapOption) {
        return this.geoObjects[this.mapOptionToGeoType(mapOption)];
    }
    constructor() {
        this.overrideMap = {
            'InProgress': { key: 'InProgress', f: (useOpacity) => this.getLegendColor('yellow', useOpacity), pos: 'top' },
            'IllegallyParked': { key: 'IllegallyParked', f: (useOpacity) => this.getLegendColor('red', useOpacity), pos: 'bottom' },
            'NotDigital': { key: 'NotDigital', f: (useOpacity) => this.getLegendColor('red', useOpacity), pos: 'bottom' },
            'Indecisive': { key: 'Indecisive', f: (useOpacity) => this.getLegendColor('brown', useOpacity), pos: 'bottom' },
            'NotProcessed': { key: 'NotProcessed', f: (useOpacity) => this.getLegendColor('grey', useOpacity), pos: 'bottom' },
            'Done': { key: 'Done', f: (useOpacity) => this.getLegendColor('green', useOpacity), pos: 'bottom' },
        };
        this.requested = {};
        this.cache = {};
        this.geoObjects = {};
    }
    async autoInit() {
        this.mapOverlayService = AEngine.get(AMapOverlayService);
        Events.hardwire('GeoResponse', (res) => {
            try {
                Events.tryInvoke(`GeoDataTimeStamps`, res.Timestamps);
                for (let key in res["Geo"]) {
                    const geoType = key;
                    if (res["Geo"][geoType] !== null) {
                        this.geoObjects[geoType] = res["Geo"][geoType]["GeoMap"];
                        for (let geoId in this.geoObjects[geoType]) {
                            this.geoObjects[geoType][geoId].Center = { lat: 0, lng: 0 };
                            this.geoObjects[geoType][geoId].RefList = [];
                        }
                        AEngine.log(`Received GeoData [%c${geoType}%n]`);
                    }
                    else {
                        AEngine.warn(`No GeoData of type ${geoType} available`);
                    }
                    this.requested[geoType] = true;
                    Events.tryInvoke(`GeoResponse_${geoType}`);
                }
            }
            catch (err) {
                AError.handle(err);
            }
        });
    }
    getGeoInstancesOnMapAll(mapInstance) {
        const map = (mapInstance ?? script?.map);
        return map?._geoInstances;
    }
    getGeoInstancesOnMap(mapInstance, input) {
        const map = (mapInstance ?? script?.map);
        const geoType = ALL_GEO_TYPES.includes(input) ? input : this.mapOptionToGeoType(input);
        const geoInstances = map?._geoInstances[geoType] || this.cache[geoType];
        return (geoInstances || []).filter(v => v != null);
    }
    /**
     * Display Popup window in order to filter Geo Layers on map
     * @param map map that countains the geo layers
     */
    async filterGeoLayerSelect(map) {
        try {
            // Get visible geo layers on page
            const availableLayers = ALL_GEO_TYPES.filter((geoType) => {
                return (map._geoObjectsVisible[geoType] === true);
            }).filter(v => v !== undefined);
            if (availableLayers.length === 0) {
                Alerts.show({
                    title: ALERT_TITLES.Error,
                    content: await Translate.get('You need to enable atleast 1 GeoLayer!'),
                    type: ALERTS.Error
                });
                return;
            }
            // Declare & initialize a form so the user can choose which layer he/she wants to filter
            const form = new AFormInstance({
                ignoreWildcards: true,
                formInputs: [{
                        id: 'geoLayer',
                        label: '',
                        type: 'select',
                        options: availableLayers.map((v) => ({ id: v, text: v })),
                        disallowNone: true,
                        hint: 'Please Select a Visible GeoLayer',
                    }],
            });
            const events = Alerts.show({
                translatedTitle: await Translate.get('Filter GeoLayer'),
                content: await form.generate({ translate: true }),
                buttons: ALERT_BUTTONS.filterCancel
            });
            await form.injectFormData();
            await form.initFormValidation();
            events.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
                // Upon user input, extract data from form
                const data = form.extractFormData({ cleanData: true });
                if (data === null) {
                    return false;
                }
                // Generate and display filters for the current geolayer
                Loading.waitForPromises(this.filterGeoLayers(map, data.geoLayer)).catch(AError.handle);
            });
        }
        catch (err) {
            AError.handle(err);
        }
    }
    async filterGeoLayers(map, geoType) {
        // Convert geoType to mapOption
        const mapOption = this.geoTypeToMapOption(geoType);
        // Get all polygons of selected geo layer
        const polygons = this.getGeoInstancesOnMap(map, geoType);
        // Retreive the geoMapper in order to get data of the polygons
        const geoMapper = mapHelperService.geoObjectMapper(mapOption);
        // Display a toast when no polygons are found
        if (polygons.length === 0) {
            AEngine.warn(`No Polygons loaded!`);
            return toast({ msg: await Translate.get('Please Load the GeoLayer beforehand!') });
        }
        // Compile all possible values for every attribute
        let optMapping = {};
        polygons.map(p => {
            const attrs = geoMapper[p.data.id].Attributes;
            Object.keys(attrs).map(key => {
                const value = attrs[key];
                if (typeof value === 'number')
                    return;
                if (!optMapping.hasOwnProperty(key)) {
                    optMapping[key] = new Set();
                }
                optMapping[key].add(value);
            });
        });
        // Create id mapper in order to transform input ids to attribute keys
        // This method is used because some Attribute keys contain illegal html characters & symbols
        const idAllocator = new AIdAllocator({ padStart: 0, startId: 0 });
        const form = new AFormInstance({
            ignoreWildcards: true,
            formInputs: Object.keys(optMapping).map(field => {
                const id = idAllocator.getNextId({ prefix: 'inp-' });
                const baseOpt = { id, label: field, width: 'col-4' };
                if (optMapping[field].size > 100) {
                    return { ...baseOpt, type: 'text', minlength: 0, maxlength: 256 };
                }
                const arr = [...optMapping[field]];
                if (arr.length === 1 && arr[0] == '') {
                    return { ...baseOpt, type: 'text', minlength: 0, maxlength: 256 };
                }
                return { ...baseOpt, type: 'select', options: [{ id: '%', text: 'Any' }, ...arr.map(v => ({ id: v, text: v }))] };
            })
        });
        // Display Second Alert containing all the geo layer filters
        const events = Alerts.show({
            translatedTitle: await Translate.get('Filter GeoLayers'),
            content: await form.generate({ translate: false, wrapInColumns: true }),
            type: ALERTS.Large
        });
        await form.injectFormData();
        await form.initFormValidation();
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            // Prepare for counting visible/invisible geo layers
            let visible = 0, total = polygons.length;
            // Extract data from filter modal
            const tmpData = form.extractFormData({ cleanData: true });
            // Declare data object to dump filters in
            const data = {};
            // Cache input type, so that we can use wildcards when using with text inputs
            const inputTypes = {};
            // Transform ids back to orginal keys & store it in data obj
            Object.keys(tmpData).filter(str => tmpData[str] !== '').map(str => {
                const k = Object.keys(optMapping)[str.split('inp-').pop()];
                data[k] = tmpData[str];
                inputTypes[str] = form.formInputs.find(inp => inp.id === str)?.type;
            });
            if (AEngine.isDevelopmentMode) {
                AEngine.log('filterGeoLayers', { optMapping, form, data });
            }
            // Get filter key to start searching
            const filterKey = (Object.keys(data) ?? []).shift(); // TODO: Allow multiple filterKeys
            // Get filter value to start looking for
            const find = data[filterKey]?.toLowerCase();
            if (find !== undefined) {
                // Whether the search should be wildcard
                const isText = Object.values(inputTypes)[0];
                // Process all polygons
                polygons.map(geoInstance => {
                    const currData = geoMapper[geoInstance.data.id].Attributes[filterKey]?.toLowerCase();
                    const isMatch = isText ? (currData?.indexOf(find) !== -1) : (currData === find);
                    if (isMatch) {
                        visible++;
                    }
                    // Toggle polygon visibility
                    geoInstance.setVisible(isMatch);
                });
            }
            // Center on geo layers if the results are few and the geo layer is small
            if (visible < 30 && mapOption >= MAP_OPTIONS.Zone) {
                map.focusOnGeoLayers();
            }
            // Display toast
            toast({ msg: await Translate.get(`Showing ${visible}/${total} GeoLayers of type: ${geoType}`), timeout: 7500 });
        });
    }
    /**
     * Move polygons from one map to another
     */
    async redirectPolygons(map, polygons) {
        await asyncMapArray(polygons, 20, (geoInstance) => {
            geoInstance.setOptions({ map, visible: true });
        });
    }
    fetchStreetData(coordinates) {
        return new Promise((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'location': coordinates }, function (results, status) {
                if (status !== 'OK') {
                    return reject(status);
                }
                return resolve(results);
            });
        });
    }
    geoPointToCoords(data, srid = 4326) {
        //data = {"type": "Point", "coordinates": [10.750851301946682, 59.908290312191056]}
        // PageScript.map.setCenter(new google.maps.LatLng(59.9267065136985, 10.8019512068214))
        if (srid == 4326) {
            const [lng, lat] = data.coordinates;
            return { lat, lng };
        }
        else {
            const [lat, lng] = data.coordinates;
            return { lat, lng };
        }
    }
    geoJsonToPolygonCoords(data, srid = 4326) {
        const { coordinates } = data;
        let center = { lat: 0, lng: 0 };
        let areaPaths = [];
        for (let r = 0; r < coordinates.length; r++) {
            let ring = [];
            for (let p = 0; p < coordinates[r].length; p++) {
                let latlng;
                if (srid == 4326) {
                    latlng = { lat: coordinates[r][p][1], lng: coordinates[r][p][0] };
                }
                else {
                    latlng = { lat: coordinates[r][p][0], lng: coordinates[r][p][1] };
                }
                if (r == 0) {
                    center.lat += latlng.lat;
                    center.lng += latlng.lng;
                }
                ring.push(latlng);
            }
            if (r == 0) {
                center.lat /= coordinates[r].length;
                center.lng /= coordinates[r].length;
            }
            areaPaths.push(ring);
        }
        if (areaPaths.length == 1) {
            areaPaths = areaPaths[0];
        }
        return {
            coordinates: areaPaths,
            center: center
        };
    }
    getLegendColor(name, useOpacity) {
        switch (name) {
            case "purple": return {
                fill: useOpacity ? new AColor(171, 0, 227).rgba(0.5) : new AColor(171, 0, 227).hexi,
                stroke: new AColor(171, 0, 227).hexi
            };
            case "green": return {
                fill: useOpacity ? new AColor(0, 255, 0).rgba(0.5) : new AColor(0, 255, 0).hexi,
                stroke: new AColor(3, 100, 0).hexi
            };
            case "yellow": return {
                fill: useOpacity ? new AColor(255, 255, 0).rgba(0.5) : new AColor(255, 255, 0).hexi,
                stroke: new AColor(128, 128, 0).hexi
            };
            case "orange": return {
                fill: useOpacity ? new AColor(255, 133, 0).rgba(0.5) : new AColor(255, 133, 0).hexi,
                stroke: new AColor(128, 60, 0).hexi
            };
            case "red": return {
                fill: useOpacity ? new AColor(247, 0, 0).rgba(0.5) : new AColor(247, 0, 0).hexi,
                stroke: new AColor(131, 0, 0).hexi
            };
            case "blue": return {
                fill: useOpacity ? new AColor(0, 173, 255).rgba(0.5) : new AColor(0, 173, 255).hexi,
                stroke: new AColor(0, 114, 232).hexi
            };
            case "grey": return {
                fill: useOpacity ? new AColor(128, 128, 128).rgba(0.5) : new AColor(128, 128, 128).hexi,
                stroke: new AColor(68, 68, 68).hexi
            };
            case "white": return {
                fill: useOpacity ? new AColor(255, 255, 255).rgba(0.5) : new AColor(255, 255, 255).hexi,
                stroke: new AColor(128, 128, 128).hexi
            };
            case "brown": return {
                fill: useOpacity ? new AColor(200, 100, 0).rgba(0.5) : new AColor(200, 100, 0).hexi,
                stroke: new AColor(100, 50, 0).hexi
            };
        }
    }
    get_legend_brown_outline() {
        return {
            fill: new AColor(200, 100, 0).rgba(0.0),
            stroke: new AColor(100, 50, 0).hexi
        };
    }
    get legend_legacy() {
        const { legendItems } = {
            legendItems: {
                'Default': this.getLegendColor('green', true),
                'No Parking Right': this.getLegendColor('red', true),
                'Illegally Parked': this.get_legend_brown_outline(),
                'Unknown Parking Right': this.getLegendColor('grey', true)
            }
        };
        return {
            calcColor: ({ IsIllegallyParked, HasParkingRight }) => {
                const { FillColor, StrokeColor } = this.getColorLegacy({ IsIllegallyParked, HasParkingRight });
                return {
                    fill: FillColor,
                    stroke: StrokeColor
                };
            },
            legendItems
        };
    }
    get legend_digital2() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'Digital': this.getLegendColor('green', false),
                'NotDigital': this.getLegendColor('red', false),
                'InProgress': this.getLegendColor('yellow', false),
                'NotProcessed': this.getLegendColor('grey', false)
            },
            legendItems: {
                'Digital': this.getLegendColor('green', true),
                'NotDigital': this.getLegendColor('red', true),
                'InProgress': this.getLegendColor('yellow', true),
                'NotProcessed': this.getLegendColor('grey', true)
            }
        };
        return {
            calcColor: ({ Digital }) => {
                if (Digital === null)
                    return null;
                switch (Digital) {
                    case 'InProgress':
                        return calcTemplate.InProgress;
                    case 'Digital':
                        return calcTemplate.Digital;
                    case 'NotDigital':
                        return calcTemplate.NotDigital;
                    case 'NotProcessed':
                        return calcTemplate.NotProcessed;
                }
            },
            legendItems
        };
    }
    get legend_illegallyparked2() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'NotIllegallyParked': this.getLegendColor('green', false),
                'IllegallyParked': this.getLegendColor('red', false),
                'InProgress': this.getLegendColor('yellow', false),
                'NotProcessed': this.getLegendColor('grey', false)
            },
            legendItems: {
                'NotIllegallyParked': this.getLegendColor('green', true),
                'IllegallyParked': this.getLegendColor('red', true),
                'InProgress': this.getLegendColor('yellow', true),
                'NotProcessed': this.getLegendColor('grey', true)
            }
        };
        return {
            calcColor: ({ IllegallyParked }) => {
                if (IllegallyParked === null)
                    return null;
                switch (IllegallyParked) {
                    case 'NotIllegallyParked':
                        return calcTemplate.NotIllegallyParked;
                    case 'InProgress':
                        return calcTemplate.InProgress;
                    case 'NotProcessed':
                        return calcTemplate.NotProcessed;
                }
                if (IllegallyParked?.startsWith('IllegallyParked')) {
                    return calcTemplate.IllegallyParked;
                }
            },
            legendItems
        };
    }
    get legend_parkingright2() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'ParkingRight': this.getLegendColor('green', false),
                'NoParkingRight': this.getLegendColor('red', false),
                'InProgress': this.getLegendColor('yellow', false),
                'Other': this.getLegendColor('grey', false)
            },
            legendItems: {
                'ParkingRight': this.getLegendColor('green', true),
                'NoParkingRight': this.getLegendColor('red', true),
                'InProgress': this.getLegendColor('yellow', true),
                'Other': this.getLegendColor('grey', true)
            }
        };
        return {
            calcColor: ({ ParkingRight }) => {
                if (ParkingRight === null)
                    return null;
                switch (ParkingRight) {
                    case 'InProgress': return calcTemplate.InProgress;
                    case 'NoParkingRight': return calcTemplate.NoParkingRight;
                }
                if (ParkingRight?.startsWith('NoParkingRightNeeded')) {
                    return calcTemplate.Other;
                }
                if (ParkingRight?.startsWith('ParkingRight')) {
                    return calcTemplate.ParkingRight;
                }
                if (ParkingRight?.startsWith('Indecisive')) {
                    return calcTemplate.NoParkingRight;
                }
                if (ParkingRight?.startsWith('NotProcessed')) {
                    return calcTemplate.Other;
                }
            },
            legendItems
        };
    }
    getLeafUnifications(unification) {
        if (!unification.Options || Object.keys(unification.Options).length === 0) {
            return unification.Key;
        }
        return Object.keys(unification.Options).map(key => this.getLeafUnifications(unification.Options[key])).flat();
    }
    get legend_parkingright() {
        const unify = new AParkingRight();
        const colorKeys = Object.values(unify.Options).map(subOpt => subOpt.Key);
        return AColorMapper.mapToColors(colorKeys, {
            useOpacity: true,
            calcColorKey: 'ParkingRight',
            overrideMap: this.overrideMap,
            offset: 1,
            hue: 120,
            hueDir: -1,
        });
    }
    get legend_verification() {
        const unify = new AVerification();
        const colorKeys = Object.values(unify.Options).map(subOpt => subOpt.Key);
        return AColorMapper.mapToColors(colorKeys, {
            useOpacity: true,
            calcColorKey: 'Verification',
            overrideMap: this.overrideMap,
            offset: 1,
            hue: 0,
            hueDir: -1,
        });
    }
    get legend_illegallyparked() {
        const unify = new AIllegallyParked();
        new AIllegallyParked().Options.NotIllegallyParked.Key;
        const colorKeys = Object.values(unify.Options).map(subOpt => subOpt.Key);
        return AColorMapper.mapToColors(colorKeys, {
            useOpacity: true,
            calcColorKey: 'IllegallyParked',
            overrideMap: this.overrideMap,
            hue: PageScript?.hue ?? 120,
        });
    }
    get legend_digital() {
        const unify = new ADigital();
        const colorKeys = Object.values(unify.Options).map(subOpt => subOpt.Key);
        return AColorMapper.mapToColors(colorKeys, {
            useOpacity: true,
            calcColorKey: 'Digital',
            overrideMap: this.overrideMap,
            hue: 120,
            hueDir: -1,
        });
    }
    get legend_detection_state() {
        const unify = new ADetectionState();
        const colorKeys = this.getLeafUnifications(unify); // [...Object.values(unify.Options.InProgress.Options).map(subOpt => subOpt.Key), unify.Options.Done.Key]
        return AColorMapper.mapToColors(colorKeys, {
            useOpacity: true,
            calcColorKey: 'DetectionState',
            overrideMap: this.overrideMap,
            hue: 0,
            offset: -1,
            hueDir: -1,
            transformHue: (hue, left = 80, right = 150) => {
                const range = (360.0 - (right - left));
                let output = right + hue * (range / 360.0);
                while (output < 0.0)
                    output += 360.0;
                while (output > 360.0)
                    output -= 360.0;
                return output;
            },
        });
    }
    set calcLegendColor(val) {
        PageScript.__map_legend_calcColor = val;
    }
    get calcLegendColor() {
        const fallbackClr = { fill: '#000', stroke: '#000' };
        const calcColor = PageScript.__map_legend_calcColor ?? this.getCalcLegendConfig();
        if (calcColor == undefined) {
            AError.handleSilent(`Page calcLegendColor has not been initialized!`);
            return (_ => (fallbackClr));
        }
        const resultFunc = (args) => {
            const clr = calcColor(args);
            if (clr === null) {
                AError.handle({
                    err: new Error(`AMapHelperService.calcLegendColor unification is null data=${JSON.stringify(args)}`),
                    useAdminAlerts: false,
                    useCentralServerLogging: false,
                    useModal: false,
                });
            }
            else if (clr === undefined) {
                AError.handleSilent(`AMapHelperService.calcLegendColor unexpected unification (POSSIBLY MISSING UNIFICATIONS) data=${JSON.stringify(args)}`, ERROR_GROUPS.CalcLegendColorError);
            }
            return clr || fallbackClr;
        };
        return resultFunc;
    }
    get tabDefinitions() {
        if (AEngine.isDevelopmentMode) {
            // TODO: Remove hardcoded aspect
            AEngine.warn(`// TODO: Remove hardcoded aspect`);
        }
        const tabOptions = [
            'ParkingRight',
            'Verification',
            'DetectionState',
            'IllegallyParked',
            'Digital',
        ];
        const tabConfigurations = {
            'ParkingRight': this.legend_parkingright,
            'Verification': this.legend_verification,
            'DetectionState': this.legend_detection_state,
            'IllegallyParked': this.legend_illegallyparked,
            'Digital': this.legend_digital,
        };
        return { tabOptions, tabConfigurations };
    }
    async generateDetectionsLegendHtml(opt) {
        const { legendItems, tabOptions } = opt;
        const selectedLegend = this.legendSelection || tabOptions[0];
        const html = await requestService.translateDom(/*html*/ `
      <div class="legend legend-opaque legend-detections">
        <div class="legend-label label-height-lg hidden">Legend</div>
        <select class="legend-change form-select select-sm">
        ${tabOptions.map(opt => (`<option${selectedLegend == opt ? ' selected="selected"' : ''} value="${opt}">${opt}</option>`)).join('')}
        </select>
        ${Object.keys(legendItems).map(key => ({ key, ...legendItems[key] })).map(({ key, stroke, fill }) => ( /*html*/`
            <div class="legend-item">
              <div class="detection-preview" style="background-color: ${fill}; border-color: ${stroke}"></div>
              <span>${key}</span>
            </div>
        `)).join('')}
      </div>
    `);
        return $(html);
    }
    /**
     * Implemented because mapHelperService.calcLegendColor is not always set!
     */
    getCalcLegendConfig() {
        try {
            const { tabConfigurations } = this.tabDefinitions;
            const legendKey = this.legendSelection ?? Object.keys(tabConfigurations)[0];
            const legendConfig = tabConfigurations[legendKey] || this.legend_parkingright;
            // this.calcLegendColor = legendConfig.calcColor
            return legendConfig.calcColor;
        }
        catch (err) {
            // AEngine.warn(err)
            console.error(err);
            AError.handleSilent('getCalcLegendConfig Failed, using color: #000');
            return (args, options) => {
                return {
                    fill: '#000000',
                    stroke: '#000000'
                };
            };
        }
    }
    async setDetectionsLegend(map, mapElement, opt) {
        const { tabConfigurations, tabOptions } = this.tabDefinitions;
        this.legendSelection = opt?.value ?? Object.keys(tabConfigurations)[0];
        const uid = opt?.uid ?? idAllocatorService.getNextId({ prefix: 'legend-controls-' });
        const legendConfig = (tabConfigurations[this.legendSelection] || this.legend_parkingright);
        const $detectionsLegend = await this.generateDetectionsLegendHtml({ tabOptions, ...legendConfig });
        const $select = $detectionsLegend.find('.legend-change');
        $select.on('change', _ => Loading.waitForPromises(this.setDetectionsLegend(map, mapElement, { value: $select.val(), uid })).catch(AError.handle));
        this.calcLegendColor = legendConfig.calcColor;
        this.mapOverlayService.add(map, mapElement, $detectionsLegend, MAP_POSITION.BOTTOM_LEFT, { uid, order: 0 });
        $select.trigger('focus');
        await this.recolorAllMarkers(legendConfig);
    }
    async recolorAllMarkers(options) {
        const fallbackClr = { fill: '#000', stroke: '#000' };
        const calcColor = options.calcColor || this.calcLegendColor;
        const markers = this.fetchMarkers();
        await asyncMapArray(markers, 10, (marker) => {
            const optClr = calcColor(marker._final, { opacity: true });
            if (optClr === null) {
                AError.handle({
                    err: new Error(`AMapHelperService.recolorAllMarkers unification is null data=${JSON.stringify(marker._final)}`),
                    useAdminAlerts: false,
                    useCentralServerLogging: false,
                    useModal: false,
                });
            }
            else if (optClr === undefined) {
                AError.handleSilent(`AMapHelperService.recolorAllMarkers unexpected unification (POSSIBLY MISSING UNIFICATIONS) data=${JSON.stringify(marker._final)}`, ERROR_GROUPS.CalcMarkerColorError);
            }
            const clr = optClr || fallbackClr;
            const { fill, stroke } = (typeof clr === 'string') ? { fill: clr, stroke: clr } : clr;
            marker.setOptions({
                strokeColor: stroke,
                fillColor: fill
            });
        });
    }
    async createCountLabel() {
        const mapCountLabel = document.createElement('label');
        mapCountLabel.classList.add('map-control-count');
        const template = await Translate.get('Detections Displayed: 808');
        mapCountLabel.setAttribute('template', template);
        mapCountLabel.innerText = template.replace('808', '0');
        PageScript.map?.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(mapCountLabel);
    }
    updateCountLabel(count) {
        const $count = $('.map-control-count');
        if ($count.length) {
            const template = $count.attr('template') || '';
            const text = template.replace('808', count);
            $count.text(text);
        }
    }
    async createButton(options) {
        const $button = await menuService.addMapButton({
            ...options,
            tag: 'div',
            // title: '',
            position: MAP_POSITION.TOP_RIGHT,
        });
        return $button;
    }
    async createDownloadButton(options) {
        const { map, mapElement, order } = options;
        const $button = await menuService.addMapButton({
            map,
            mapElement,
            tag: 'a',
            title: '',
            titleTag: 'span',
            icon: 'fa-solid fa-file-arrow-down',
            order,
            position: MAP_POSITION.TOP_RIGHT,
        });
        $button.attr('id', 'DownloadButton');
        $button.attr('disabled', 'disabled');
        return $button;
    }
    geoTypeToMapOption(geoType) {
        for (let opt in MAP_OPTIONS) {
            let isKeyNumber = Number(opt) >= 0;
            if (!isKeyNumber && opt === geoType) {
                return MAP_OPTIONS[opt];
            }
        }
        return MAP_OPTIONS.None;
    }
    mapOptionToGeoType(type) {
        for (let opt of ALL_MAP_OPTIONS) {
            if (opt & type) {
                return MAP_OPTIONS[type];
            }
        }
        throw new Error(`UnknownGeoType: ${type}`);
    }
    showGeoObjectOnMap(map, geoType, geoObject, result, bounds, clickEvent) {
        const mapOption = this.geoTypeToMapOption(geoType);
        const colorConfig = this.getGeoObjectColor(mapOption, geoObject);
        const zIndex = this.getGeoObjectZIndex(mapOption);
        switch (geoObject.Geo.type) {
            case "Point": {
                const [lng, lat] = geoObject.Geo.coordinates;
                let mapsGeo = new google.maps.Marker({
                    position: new google.maps.LatLng(lat, lng)
                });
                Object.assign(mapsGeo, {
                    kmlName: geoObject.Name,
                    data: {
                        id: geoObject.Index,
                        scale: mapOption,
                        geoType: geoType
                    }
                });
                result.push(mapsGeo);
                mapsGeo.setMap(map);
                google.maps.event.addListener(mapsGeo, "click", clickEvent || function () { return purgatoryService.clickPolygon.apply(this, [map]); });
                return;
            }
            case "LineString": {
                let parkingSpaceBounds = geoObject.Geo.coordinates;
                let path = [];
                let center = { lat: 0, lng: 0 };
                for (let p = 0; p < parkingSpaceBounds.length; p++) {
                    let latlng = {
                        lat: parkingSpaceBounds[p][1],
                        lng: parkingSpaceBounds[p][0]
                    };
                    if (bounds)
                        bounds.extend(latlng);
                    center.lat += latlng.lat;
                    center.lng += latlng.lng;
                    path.push(latlng);
                }
                center.lat /= parkingSpaceBounds.length;
                center.lng /= parkingSpaceBounds.length;
                geoObject.Center = center;
                // Construct the polygon, including both paths.
                let mapsGeo = new google.maps.Polyline({
                    path: path,
                    strokeColor: colorConfig.strokeColor,
                    strokeOpacity: colorConfig.strokeOpacity,
                    strokeWeight: 3,
                    // fillColor: colorConfig.fillColor,
                    // fillOpacity: colorConfig.fillOpacity,
                    zIndex: zIndex,
                    // position: center
                });
                Object.assign(mapsGeo, {
                    kmlName: geoObject.Name,
                    data: {
                        id: geoObject.Index,
                        scale: mapOption,
                        geoType: geoType
                    }
                });
                geoObject.RefList.push(mapsGeo);
                result.push(mapsGeo);
                mapsGeo.setMap(map);
                google.maps.event.addListener(mapsGeo, "click", clickEvent || function () { return purgatoryService.clickPolygon.apply(this, [map]); });
                return;
            }
            case "Polygon": {
                let parkingSpaceBounds = geoObject.Geo.coordinates;
                let parkingSpacePaths = [];
                let center = { lat: 0, lng: 0 };
                for (let r = 0; r < parkingSpaceBounds.length; r++) {
                    center = { lat: 0, lng: 0 };
                    let ring = [];
                    for (let p = 0; p < parkingSpaceBounds[r].length; p++) {
                        let latlng = {
                            lat: parkingSpaceBounds[r][p][1],
                            lng: parkingSpaceBounds[r][p][0]
                        };
                        if (bounds)
                            bounds.extend(latlng);
                        center.lat += latlng.lat;
                        center.lng += latlng.lng;
                        ring.push(latlng);
                    }
                    center.lat /= parkingSpaceBounds[r].length;
                    center.lng /= parkingSpaceBounds[r].length;
                    parkingSpacePaths.push(ring);
                }
                if (parkingSpacePaths.length == 1) {
                    parkingSpacePaths = parkingSpacePaths[0];
                }
                geoObject.Center = center;
                // Construct the polygon, including both paths.
                let mapsGeo = new google.maps.Polygon({
                    paths: parkingSpacePaths,
                    strokeColor: colorConfig.strokeColor,
                    strokeOpacity: colorConfig.strokeOpacity,
                    strokeWeight: .5,
                    fillColor: colorConfig.fillColor,
                    fillOpacity: colorConfig.fillOpacity,
                    zIndex: zIndex,
                    // position: center
                });
                Object.assign(mapsGeo, {
                    kmlName: geoObject.Name,
                    data: {
                        id: geoObject.Index,
                        scale: mapOption,
                        geoType: geoType
                    }
                });
                // Apply ref for fast lookup
                geoObject.RefList.push(mapsGeo);
                result.push(mapsGeo);
                mapsGeo.setMap(map);
                google.maps.event.addListener(mapsGeo, "click", clickEvent || function () { return purgatoryService.clickPolygon.apply(this, [map]); });
                return;
            }
            case "MultiPolygon": {
                for (let g = 0; g < geoObject.Geo.coordinates.length; g++) {
                    let parkingSpaceBounds = geoObject.Geo.coordinates[g];
                    let parkingSpacePaths = [];
                    let center = { lat: 0, lng: 0 };
                    for (let r = 0; r < parkingSpaceBounds.length; r++) {
                        center = { lat: 0, lng: 0 };
                        let ring = [];
                        for (let p = 0; p < parkingSpaceBounds[r].length; p++) {
                            let latlng = {
                                lat: parkingSpaceBounds[r][p][1],
                                lng: parkingSpaceBounds[r][p][0]
                            };
                            if (bounds)
                                bounds.extend(latlng);
                            center.lat += latlng.lat;
                            center.lng += latlng.lng;
                            ring.push(latlng);
                        }
                        center.lat /= parkingSpaceBounds[r].length;
                        center.lng /= parkingSpaceBounds[r].length;
                        parkingSpacePaths.push(ring);
                    }
                    if (parkingSpacePaths.length == 1) {
                        parkingSpacePaths = parkingSpacePaths[0];
                    }
                    geoObject.Center = center;
                    // Construct the polygon, including both paths.
                    let mapsGeo = new google.maps.Polygon({
                        paths: parkingSpacePaths,
                        strokeColor: colorConfig.strokeColor,
                        strokeOpacity: colorConfig.strokeOpacity,
                        strokeWeight: .5,
                        fillColor: colorConfig.fillColor,
                        fillOpacity: colorConfig.fillOpacity,
                        zIndex: zIndex,
                        // position: center
                    });
                    Object.assign(mapsGeo, {
                        kmlName: geoObject.Name,
                        data: {
                            id: geoObject.Index,
                            scale: mapOption,
                            geoType: geoType
                        }
                    });
                    // Apply ref for fast lookup
                    geoObject.RefList.push(mapsGeo);
                    result.push(mapsGeo);
                    mapsGeo.setMap(map);
                    google.maps.event.addListener(mapsGeo, "click", clickEvent || function () { return purgatoryService.clickPolygon.apply(this, [map]); });
                }
                return;
            }
            case "Collection": /// ACCCServer incorrectly calls this Collection (http://gitserver/aci-software/ACCCServer/issues/28)
            case "GeometryCollection":
                let geometries = geoObject.Geo.geometries || geoObject.Geo.items; /// ACCCServer incorrectly calls this items  (http://gitserver/aci-software/ACCCServer/issues/28)
                for (let i = 0; i < geometries.length; i++) {
                    let newGeoObject = { Index: geoObject.Index, Geo: geometries[i], Name: geoObject.Name, Attributes: geoObject.Attributes, Created: geoObject.Created, Modified: geoObject.Modified, RefList: geoObject.RefList, Center: geoObject.Center };
                    this.showGeoObjectOnMap(map, geoType, newGeoObject, result, bounds, clickEvent);
                }
                return;
            default:
                throw new Error(`Unknown geo json type: ${geoObject.Geo.type}`);
        }
    }
    async showGeoObjectsOnMap(geoType, map, bounds, clickEvent) {
        if (this.cache[geoType]) {
            for (const polygon of this.cache[geoType] ?? []) {
                google.maps.event.clearListeners(polygon, "click");
                google.maps.event.addListener(polygon, "click", function () { return purgatoryService.clickPolygon.apply(this, [map]); });
            }
            await this.redirectPolygons(map, this.cache[geoType] ?? []);
            return this.cache[geoType];
        }
        let result = [];
        await asyncMapArray(Object.keys(this.geoObjects[geoType] ?? []), 20, (id) => {
            return this.showGeoObjectOnMap(map, geoType, this.geoObjects[geoType][id], result, bounds, clickEvent);
        });
        this.cache[geoType] = result;
        return result;
    }
    /**
     * Creates a toggle button on the map to hide a category
     * @param bitmask only apply on specific type of polygons on the map
     * @param options options
     */
    async createMapToggleSettings(bitmask, { map, order, fitPolygons = true, showOneScale, click }) {
        map._geoObjectsVisible = {};
        const t = await Translate.get(ALL_GEO_TYPES);
        let categories = ALL_GEO_MAPPING.map(({ geoType, mapOption }) => {
            map._geoObjectsVisible[geoType] = false;
            return (mapOption & bitmask) ? { geoType, mapOption, text: t[geoType] ?? '?', isCheckbox: true } : null;
        }).filter(v => v != null);
        if (showOneScale === true) {
            const bounds = map.getBounds() || new google.maps.LatLngBounds();
            await Loading.waitForPromises(categories.map(async ({ geoType }) => {
                await this.showGeoObjectsOnMap.call(this, geoType, map, bounds, click);
            }));
            if (fitPolygons === true) {
                map.fit(bounds);
            }
        }
        const toggleTo = (geoType, $c) => this.toggleTo(map, geoType, click).finally(() => $c.map($c => $c.trigger('refreshstate')));
        const toggle = (geoType, $c) => this.toggle(map, geoType, click, !map._geoObjectsVisible[geoType]).finally(() => $c.map($c => $c.trigger('refreshstate')));
        const toggleAction = showOneScale ? toggleTo : toggle;
        const selectDefault = (showOneScale === true) ? ($c) => toggleAction('ParkingSpace', $c) : undefined;
        const $aArr = [];
        const $cArr = [];
        categories.map(({ geoType, text }) => {
            const $a = $(/*html*/ `
        <a geoType="${geoType}" isLoaded="${this.isLoaded(geoType) ? '1' : '0'}">
          <div class="noselect ns-children"><label class="form-checkbox"><input type="checkbox" class="hidden noselect"> <i class="form-icon"></i>${text}</label></div>
        </a>
      `);
            const $checkbox = $a.find(`[type="checkbox"]`);
            $checkbox.on('refreshstate', (e) => {
                $a.attr('isLoaded', this.isLoaded(geoType) ? '1' : '0');
                const visibleMap = map._geoObjectsVisible ?? {};
                $checkbox.prop('checked', visibleMap[geoType]);
            });
            $a.on('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                $checkbox.trigger('change');
                $checkbox.trigger('refreshstate');
            });
            $checkbox.on('change', _ => Loading.waitForPromises(toggleAction(geoType, $cArr)).catch(AError.handle));
            $aArr.push($a);
            $cArr.push($checkbox);
            // this.genMapPopoverItem(map, c)
        });
        if (selectDefault != null) {
            Loading.waitForPromises(selectDefault($cArr)).catch(AError.handle);
        }
        if (bitmask !== 0) {
            menuService.addMapDropdown($aArr, {
                map,
                mapElement: map.getDiv(),
                uid: 'scale-toggle',
                icon: 'fa-solid fa-draw-polygon',
                order,
                position: MAP_POSITION.TOP_LEFT,
                seperatorIndices: [categories.findIndex(c => c.geoType === 'Address')].filter(v => v !== -1)
            });
        }
    }
    applyMapPreferences(map, opt) {
        let { mapStyle, landmarkDensity, labelDensity } = Object.assign({
            mapStyle: preferenceService.load(APrefs.MAP_STYLE, '0'),
            labelDensity: preferenceService.load(APrefs.MAP_LABEL_DENSITY, 'normal'),
            landmarkDensity: preferenceService.load(APrefs.MAP_LANDMARK_DENSITY, 'normal'),
        }, opt, map.get('lockPreferences'));
        const mapStyles = {};
        if ($(map.getDiv()).attr('quick-hide-labels')) {
            labelDensity = 'hidden';
        }
        if (mapStyle !== undefined) {
            this.applyMapStyler(Number(mapStyle), {
                mapStyles,
                allOptions: {
                    0: [],
                    1: [
                        { elementType: "geometry", stylers: [{ "color": "#f5f5f5" }] },
                        { elementType: "labels.text.fill", stylers: [{ "color": "#616161" }] },
                        { elementType: "labels.text.stroke", stylers: [{ "color": "#f5f5f5" }] },
                        { featureType: "administrative.land_parcel", elementType: "labels.text.fill", stylers: [{ "color": "#bdbdbd" }] },
                        { featureType: "poi", elementType: "geometry", stylers: [{ "color": "#eeeeee" }] },
                        { featureType: "poi", elementType: "labels.text.fill", stylers: [{ "color": "#757575" }] },
                        { featureType: "poi.park", elementType: "geometry", stylers: [{ "color": "#e5e5e5" }] },
                        { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ "color": "#9e9e9e" }] },
                        { featureType: "road", elementType: "geometry", stylers: [{ "color": "#ffffff" }] },
                        { featureType: "road.arterial", elementType: "labels.text.fill", stylers: [{ "color": "#757575" }] },
                        { featureType: "road.highway", elementType: "geometry", stylers: [{ "color": "#dadada" }] },
                        { featureType: "road.highway", elementType: "labels.text.fill", stylers: [{ "color": "#616161" }] },
                        { featureType: "road.local", elementType: "labels.text.fill", stylers: [{ "color": "#9e9e9e" }] },
                        { featureType: "transit.line", elementType: "geometry", stylers: [{ "color": "#e5e5e5" }] },
                        { featureType: "transit.station", elementType: "geometry", stylers: [{ "color": "#eeeeee" }] },
                        { featureType: "water", elementType: "geometry", stylers: [{ "color": "#c9c9c9" }] },
                        { featureType: "water", elementType: "labels.text.fill", stylers: [{ "color": "#9e9e9e" }] }
                    ],
                    2: [
                        { featureType: "poi", elementType: "labels.text.fill", stylers: [{ "color": "#757575" }] },
                        { featureType: "poi.park", elementType: "geometry", stylers: [{ "color": "#181818" }] },
                        { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ "color": "#616161" }] },
                        { featureType: "poi.park", elementType: "labels.text.stroke", stylers: [{ "color": "#1b1b1b" }] },
                        { elementType: "geometry", stylers: [{ "color": "#212121" }] },
                        { elementType: "labels.text.fill", stylers: [{ "color": "#757575" }] },
                        { elementType: "labels.text.stroke", stylers: [{ "color": "#212121" }] },
                        { featureType: "administrative", elementType: "geometry", stylers: [{ "color": "#757575" }] },
                        { featureType: "administrative.country", elementType: "labels.text.fill", stylers: [{ "color": "#9e9e9e" }] },
                        { featureType: "administrative.locality", elementType: "labels.text.fill", stylers: [{ "color": "#bdbdbd" }] },
                        { featureType: "road", elementType: "geometry.fill", stylers: [{ "color": "#2c2c2c" }] },
                        { featureType: "road", elementType: "labels.text.fill", stylers: [{ "color": "#8a8a8a" }] },
                        { featureType: "road.arterial", elementType: "geometry", stylers: [{ "color": "#373737" }] },
                        { featureType: "road.highway", elementType: "geometry", stylers: [{ "color": "#3c3c3c" }] },
                        { featureType: "road.highway.controlled_access", elementType: "geometry", stylers: [{ "color": "#4e4e4e" }] },
                        { featureType: "road.local", elementType: "labels.text.fill", stylers: [{ "color": "#616161" }] },
                        { featureType: "transit", elementType: "labels.text.fill", stylers: [{ "color": "#757575" }] },
                        { featureType: "water", elementType: "geometry", stylers: [{ "color": "#000000" }] },
                        { featureType: "water", elementType: "labels.text.fill", stylers: [{ "color": "#3d3d3d" }] }
                    ],
                    3: [
                        { elementType: "geometry", stylers: [{ "color": "#1d2c4d" }] },
                        { elementType: "labels.text.fill", stylers: [{ "color": "#8ec3b9" }] },
                        { elementType: "labels.text.stroke", stylers: [{ "color": "#1a3646" }] },
                        { featureType: "administrative.land_parcel", elementType: "labels.text.fill", stylers: [{ "color": "#64779e" }] },
                        // {featureType:"administrative.country",elementType:"geometry.stroke",stylers:[{"color":"#4b6878"}]},
                        // {featureType:"administrative.province",elementType:"geometry.stroke",stylers:[{"color":"#4b6878"}]},
                        { featureType: "landscape.man_made", elementType: "geometry.stroke", stylers: [{ "color": "#334e87" }] },
                        { featureType: "landscape.natural", elementType: "geometry", stylers: [{ "color": "#023e58" }] },
                        { featureType: "poi", elementType: "geometry", stylers: [{ "color": "#283d6a" }] },
                        { featureType: "poi", elementType: "labels.text.fill", stylers: [{ "color": "#6f9ba5" }] },
                        { featureType: "poi", elementType: "labels.text.stroke", stylers: [{ "color": "#1d2c4d" }] },
                        // {featureType:"poi.business",stylers:[{"visibility":"off"}]},
                        { featureType: "poi.park", elementType: "geometry.fill", stylers: [{ "color": "#023e58" }] },
                        { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ "color": "#3C7680" }] },
                        { featureType: "road", elementType: "geometry", stylers: [{ "color": "#304a7d" }] },
                        // {featureType:"road",elementType:"labels.icon",stylers:[{"visibility":"off"}]},
                        { featureType: "road", elementType: "labels.text.fill", stylers: [{ "color": "#98a5be" }] },
                        { featureType: "road", elementType: "labels.text.stroke", stylers: [{ "color": "#1d2c4d" }] },
                        { featureType: "road.highway", elementType: "geometry", stylers: [{ "color": "#2c6675" }] },
                        { featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ "color": "#255763" }] },
                        { featureType: "road.highway", elementType: "labels.text.fill", stylers: [{ "color": "#b0d5ce" }] },
                        { featureType: "road.highway", elementType: "labels.text.stroke", stylers: [{ "color": "#023e58" }] },
                        // {featureType:"transit",stylers:[{"visibility":"off"}]},
                        { featureType: "transit", elementType: "labels.text.fill", stylers: [{ "color": "#98a5be" }] },
                        { featureType: "transit", elementType: "labels.text.stroke", stylers: [{ "color": "#1d2c4d" }] },
                        { featureType: "transit.line", elementType: "geometry.fill", stylers: [{ "color": "#283d6a" }] },
                        { featureType: "transit.station", elementType: "geometry", stylers: [{ "color": "#3a4762" }] },
                        { featureType: "water", elementType: "geometry", stylers: [{ "color": "#0e1626" }] },
                        { featureType: "water", elementType: "labels.text.fill", stylers: [{ "color": "#4e6d70" }] },
                    ],
                    4: [
                        // { featureType: "poi", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi", elementType: "labels.text.fill", stylers: [{ "color": "#d59563" }] },
                        // { featureType: "poi.business", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.park", elementType: "geometry", stylers: [{ "color": "#263c3f" }] },
                        { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ "color": "#6b9a76" }] },
                        { elementType: "geometry", stylers: [{ "color": "#242f3e" }] },
                        { elementType: "labels.text.fill", stylers: [{ "color": "#746855" }] },
                        { elementType: "labels.text.stroke", stylers: [{ "color": "#242f3e" }] },
                        { featureType: "administrative.locality", elementType: "labels.text.fill", stylers: [{ "color": "#d59563" }] },
                        { featureType: "road", elementType: "geometry", stylers: [{ "color": "#38414e" }] },
                        { featureType: "road", elementType: "geometry.stroke", stylers: [{ "color": "#212a37" }] },
                        { featureType: "road", elementType: "labels.text.fill", stylers: [{ "color": "#9ca5b3" }] },
                        { featureType: "road.highway", elementType: "geometry", stylers: [{ "color": "#746855" }] },
                        { featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ "color": "#1f2835" }] },
                        { featureType: "road.highway", elementType: "labels.text.fill", stylers: [{ "color": "#f3d19c" }] },
                        // { featureType: "transit", stylers: [{ "visibility": "off" }] },
                        { featureType: "transit", elementType: "geometry", stylers: [{ "color": "#2f3948" }] },
                        { featureType: "transit.station", elementType: "labels.text.fill", stylers: [{ "color": "#d59563" }] },
                        { featureType: "water", elementType: "geometry", stylers: [{ "color": "#17263c" }] },
                        { featureType: "water", elementType: "labels.text.fill", stylers: [{ "color": "#515c6d" }] },
                        { featureType: "water", elementType: "labels.text.stroke", stylers: [{ "color": "#17263c" }] }
                    ],
                    5: [
                        { elementType: "geometry", stylers: [{ "color": "#ebe3cd" }] },
                        { elementType: "labels.text.fill", stylers: [{ "color": "#523735" }] },
                        { elementType: "labels.text.stroke", stylers: [{ "color": "#f5f1e6" }] },
                        { featureType: "administrative", elementType: "geometry.stroke", stylers: [{ "color": "#c9b2a6" }] },
                        { featureType: "administrative.land_parcel", elementType: "geometry.stroke", stylers: [{ "color": "#dcd2be" }] },
                        { featureType: "administrative.land_parcel", elementType: "labels.text.fill", stylers: [{ "color": "#ae9e90" }] },
                        { featureType: "poi", elementType: "geometry", stylers: [{ "color": "#dfd2ae" }] },
                        { featureType: "poi", elementType: "labels.text.fill", stylers: [{ "color": "#93817c" }] },
                        { featureType: "poi.park", elementType: "geometry.fill", stylers: [{ "color": "#a5b076" }] },
                        { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ "color": "#447530" }] },
                        { featureType: "landscape.natural", elementType: "geometry", stylers: [{ "color": "#dfd2ae" }] },
                        { featureType: "road", elementType: "geometry", stylers: [{ "color": "#f5f1e6" }] },
                        { featureType: "road.arterial", elementType: "geometry", stylers: [{ "color": "#fdfcf8" }] },
                        { featureType: "road.highway", elementType: "geometry", stylers: [{ "color": "#f8c967" }] },
                        { featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ "color": "#e9bc62" }] },
                        { featureType: "road.highway.controlled_access", elementType: "geometry", stylers: [{ "color": "#e98d58" }] },
                        { featureType: "road.highway.controlled_access", elementType: "geometry.stroke", stylers: [{ "color": "#db8555" }] },
                        { featureType: "road.local", elementType: "labels.text.fill", stylers: [{ "color": "#806b63" }] },
                        { featureType: "transit.line", elementType: "geometry", stylers: [{ "color": "#dfd2ae" }] },
                        { featureType: "transit.line", elementType: "labels.text.fill", stylers: [{ "color": "#8f7d77" }] },
                        { featureType: "transit.line", elementType: "labels.text.stroke", stylers: [{ "color": "#ebe3cd" }] },
                        { featureType: "transit.station", elementType: "geometry", stylers: [{ "color": "#dfd2ae" }] },
                        { featureType: "water", elementType: "geometry.fill", stylers: [{ "color": "#b9d3c2" }] },
                        { featureType: "water", elementType: "labels.text.fill", stylers: [{ "color": "#92998d" }] }
                    ],
                }
            });
        }
        if (landmarkDensity !== undefined) {
            this.applyMapStyler(landmarkDensity, {
                mapStyles,
                allOptions: {
                    'hidden': [
                        { featureType: undefined, elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        // { featureType: "administrative", elementType: "geometry", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.park", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.school", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.business", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.government", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.local", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.highway", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.arterial", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "transit", elementType: "labels.icon", stylers: [{ "visibility": "off" }] }
                    ],
                    'low': [
                        { featureType: "poi", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.park", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.school", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.business", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.government", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.local", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.highway", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.arterial", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "transit", elementType: "labels.icon", stylers: [{ "visibility": "off" }] }
                    ],
                    'normal': [
                        { featureType: "poi", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.park", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.school", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.business", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.government", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.local", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.highway", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.arterial", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "transit", elementType: "labels.icon", stylers: [{ "visibility": "on" }] }
                    ],
                    'high': [
                        { featureType: "poi", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.park", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.school", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.business", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.government", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.local", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.highway", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.arterial", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "transit", elementType: "labels.icon", stylers: [{ "visibility": "on" }] }
                    ],
                },
            });
        }
        if (labelDensity !== undefined) {
            this.applyMapStyler(labelDensity, {
                mapStyles,
                allOptions: {
                    'hidden': [
                        { featureType: undefined, elementType: "labels", stylers: [{ "visibility": "off" }] },
                        { elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        // { featureType: "road", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.local", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.highway", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.arterial", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "water", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        // { featureType: "administrative.land_parcel", stylers: [{ "visibility": "off" }] },
                        // { featureType: "administrative.neighborhood", stylers: [{ "visibility": "off" }] }
                    ],
                    'low': [
                        { elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        // { featureType: "road", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.local", elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.highway", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.arterial", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "water", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        // { featureType: "administrative.land_parcel", stylers: [{ "visibility": "off" }] },
                        // { featureType: "administrative.neighborhood", stylers: [{ "visibility": "off" }] },
                    ],
                    'normal': [
                        { elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        // { featureType: "road", elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.local", elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.highway", elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.arterial", elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "water", elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        // { featureType: "administrative.land_parcel", elementType: "labels", stylers: [{ "visibility": "off" }] },
                    ],
                    'high': [],
                },
            });
        }
        let mapStyleArray = Object.keys(mapStyles).map((k1) => {
            return Object.keys(mapStyles[k1]).map((k2) => mapStyles[k1][k2]).flat();
        }).flat().sort((a, b) => {
            let diff = ((a.featureType ?? ' ').localeCompare(b.featureType ?? ' '));
            if (diff !== 0) {
                return diff;
            }
            return ((a.elementType ?? ' ').localeCompare(b.elementType ?? ' '));
        });
        // Remove subsettings for items where
        const invisibleItems = mapStyleArray.filter(item => {
            return item.stylers[0]?.visibility === 'off';
        });
        // Find indexes of items to remove
        const itemsToRemove = invisibleItems.map(item => {
            const index = mapStyleArray.findIndex((subitem) => subitem.featureType?.startsWith(item.featureType ?? '') && subitem.featureType?.startsWith(`${item.featureType}.`));
            // const index = mapStyleArray.findIndex((subitem) => item.featureType === subitem.featureType && subitem.elementType?.startsWith(`${item.elementType}.`))
            // if (index !== -1) {
            //   console.log('parent', item, ' removing', mapStyleArray[index])
            // }
            return index;
        }).filter(v => v !== -1).sort().reverse();
        // Delete subsettings
        itemsToRemove.map(index => mapStyleArray.splice(index, 1));
        if (AEngine.isDevelopmentMode) {
            AEngine.log('styles', mapStyleArray);
        }
        map.set('styles', mapStyleArray);
        return { landmarkDensity, labelDensity, mapStyle };
    }
    applyMapStyler(value, opt) {
        const { allOptions, mapStyles } = opt;
        const stylesToApply = allOptions[value] ?? [];
        let logger = {};
        for (let s of stylesToApply) {
            if (!mapStyles.hasOwnProperty(s.featureType)) {
                mapStyles[s.featureType] = {};
            }
            if (!mapStyles[s.featureType].hasOwnProperty(s.elementType)) {
                mapStyles[s.featureType][s.elementType] = {
                    featureType: s.featureType,
                    elementType: s.elementType,
                    stylers: []
                };
            }
            var record = mapStyles[s.featureType][s.elementType];
            const logKey = [s.featureType, s.elementType].filter(v => v ? true : false).join('-');
            if (!logger.hasOwnProperty(logKey)) {
                logger[logKey] = 0;
            }
            logger[logKey]++;
            record.stylers = record.stylers.concat(s.stylers);
            // mapStyles[s.featureType!][s.elementType!].stylers.push(...s.stylers)
        }
        // AEngine.log('Counts:', logger)
        return value;
    }
    editStyle(opt) {
        const { map, defaultValue, find, behaviour } = opt;
        const styles = map.get('styles') || [];
        let index = -1;
        styles.map((style, i) => {
            if (find(style)) {
                index = i;
            }
        });
        if (index === -1) {
            const [featureType, elementType, stylers] = defaultValue();
            index = styles.push({ featureType, elementType, stylers }) - 1;
        }
        styles[index] = behaviour(styles[index]);
        map.set('styles', styles);
    }
    async showPreferences(map) {
        const form = new AFormInstance({
            ignoreWildcards: true,
            formInputs: [
                { id: 'mapStyle', type: 'select', options: mapStyleOptions },
                { id: 'labelDensity', type: 'select', options: densityOptions, width: 'col-6' },
                { id: 'landmarkDensity', type: 'select', options: densityOptions, width: 'col-6' }
            ]
        });
        const $form = await form.generate({ translate: true, wrapInColumns: true });
        await form.injectFormData({
            formData: {
                mapStyle: preferenceService.get(APrefs.MAP_STYLE) ?? '0',
                labelDensity: preferenceService.get(APrefs.MAP_LABEL_DENSITY) ?? 'normal',
                landmarkDensity: preferenceService.get(APrefs.MAP_LANDMARK_DENSITY) ?? 'normal',
            }
        });
        await form.initFormValidation();
        $form.find('input, :input').on('change', (e) => {
            setTimeout(() => {
                const formData = form.extractFormData({ cleanData: true });
                this.applyMapPreferences(map, {
                    mapStyle: Number(formData.mapStyle),
                    labelDensity: formData.labelDensity,
                    landmarkDensity: formData.landmarkDensity,
                });
            }, 10);
        });
        const events = Alerts.show({
            translatedTitle: await Translate.get('Map Preferences'),
            buttons: ALERT_BUTTONS.saveCancel,
            content: $form
        });
        events.on(ALERT_STATUS.ON_MODAL_CLOSED, ({ action }) => {
            if (action === ALERT_STATUS.ON_ACTION_PROCEED) {
                const mapPrefs = form.extractFormData({ cleanData: true, setInternalFormData: true });
                AEngine.log('Saving Map Preferences', mapPrefs);
                preferenceService.setAll({
                    [APrefs.MAP_STYLE]: mapPrefs.mapStyle,
                    [APrefs.MAP_LABEL_DENSITY]: mapPrefs.labelDensity,
                    [APrefs.MAP_LANDMARK_DENSITY]: mapPrefs.landmarkDensity,
                });
                // preferenceService.set(APrefs.MAP_STYLE, mapPrefs.mapStyle)
                // preferenceService.set(APrefs.MAP_LABEL_DENSITY, mapPrefs.labelDensity)
                // preferenceService.set(APrefs.MAP_LANDMARK_DENSITY, mapPrefs.landmarkDensity)
            }
            this.applyMapPreferences(map);
        });
        events.on(ALERT_STATUS.ON_ACTION_CANCEL, () => {
            AEngine.log('Cancel');
        });
    }
    toggleMapLabels(map) {
        const $mapEle = $(map.getDiv());
        let density = preferenceService.load(APrefs.MAP_LABEL_DENSITY, 'normal');
        if ($mapEle.attr('quick-hide-labels')) {
            $mapEle.removeAttr('quick-hide-labels');
            this.applyMapPreferences(map, { labelDensity: density });
        }
        else {
            $mapEle.attr('quick-hide-labels', '1');
            this.applyMapPreferences(map, { labelDensity: 'hidden' });
        }
        // this.editStyle({
        //   map,
        //   featureType: 'all',
        //   elementType: 'labels',
        //   find: (s) => (s.featureType === 'all' && /^labels.*/g.test(s.elementType)),
        //   defaultValue: () => ['all', 'labels', [{ visibility: (!hideLabels) ? "on" : "off" }]], // ({ stylers:  }),
        //   behaviour: (style: { stylers: AMapStyler[] }) => {
        //     style.stylers[0].visibility = (!hideLabels) ? "on" : "off"
        //     preferenceService.save(APrefs.HIDE_MAP_LABELS, hideLabels)
        //     return style
        //   }
        // })
    }
    setMapType(map, mapType) {
        map.setMapTypeId(mapType);
    }
    toggleMapLabelsOld() {
        if (!PageScript.__mapOptions) {
            PageScript.__mapOptions = {
                visibility: true
            };
        }
        PageScript.__mapOptions.visibility = !PageScript.__mapOptions.visibility;
        PageScript.map?.set('styles', [
            {
                featureType: "all",
                elementType: "labels",
                stylers: [{
                        visibility: PageScript.__mapOptions.visibility ? "on" : "off"
                    }]
            }
        ]);
    }
    genDropdownLink(opt) {
        const { displayText, icon, isCheckbox = false } = opt;
        return (isCheckbox) ? $(/*html*/ `
      <a>
        <div class="noselect ns-children">
          <label class="form-checkbox">
            <input type="checkbox" class="hidden noselect">
            <i class="form-icon"></i> ${displayText ?? ''}
          </label>
        </div>
      </a>
    `) : $(/*html*/ `<a><div class="noselect ns-children">${icon ?? ''}${displayText ?? ''}</div></a>`);
    }
    async initMapRightClick(opt) {
        const { $parent } = Object.assign({ $parent: AEngine.get(APopoverService).$container }, opt);
        let opts = [
            { label: 'Focus On Detections', click: (map) => map.focusOnMarkers() },
            { label: 'Focus On Geo Layers', click: (map) => map.focusOnGeoLayers() },
            { label: 'Filter Geo Layers', click: (map) => Loading.waitForPromises(this.filterGeoLayerSelect(map)) },
        ];
        const labels = opts.map(opt => opt.label);
        await Loading.waitForPromises(Translate.get(labels).then(t => {
            opts.map(c => c.displayText = t[c.label]);
        }));
        const allowMenu = (t) => {
            return ($(t).closest('.aci-map').length > 0 || $(t).is('.aci-map'))
                && ($(t).closest('.map-overlay').length === 0)
                && ($(t).closest('.legends').length === 0);
        };
        const extractVars = (t) => {
            const $map = $(t).is('.aci-map') ? $(t) : $(t).closest('.aci-map');
            return { map: $map.data('map'), mapElement: $map.get(0), $map };
        };
        const moveToMouse = (opt) => {
            $(`${opt.popoverSelector}`).css(opt);
        };
        $(document).on('contextmenu', '.aci-map', (e) => {
            if (!allowMenu(e.target)) {
                return;
            }
            const { $map, map } = extractVars(e.target);
            const uid = idAllocatorService.getNextId({ prefix: 'ctxm' });
            const qs = `.sidebar-popover[uid="${uid}"]`;
            // Build context menu
            const $ctxm = $(`<div id="popover-generic" class="sidebar-popover" uid="${uid}"><ul></ul></div>`);
            if ($map.closest('.modal').length > 0) {
                $ctxm.css('z-index', '1050');
            }
            opts.map((cOpt) => $ctxm.find('ul').append($('<li></li>').append(this.genDropdownLink(cOpt).on('click', () => {
                cOpt.click.apply(this, [map]);
                menuService.setVisible($(`${qs}`), false);
                $ctxm.remove();
            }))));
            map.get('contextmenu')?.remove();
            map.set('contextmenu', $ctxm);
            sleep(160).then(() => {
                $(document).on('mouseleave', `${qs},#${uid}`, (e) => menuService.setVisible($(`${qs}`), false));
            });
            Events.on(EVENTS.DESTRUCT, () => { $(document).off('mouseleave', `${qs},#${uid}`); $ctxm.remove(); });
            $parent.append($ctxm);
            menuService.setVisible($ctxm, true);
            moveToMouse({ popoverSelector: qs, left: e.clientX - 5, top: e.clientY - 5 });
        });
    }
    async createMapDropdown({ map, mapElement, order, lockPreferences }) {
        let categories = [
            { displayText: 'Toggle Labels', click: () => this.toggleMapLabels(map), icon: '<i class="fa-solid fa-tag fa-fw mr-1"></i>' },
            { displayText: 'Reset Viewport', click: () => map.resetBounds(), icon: '<i class="fa-solid fa-users-viewfinder fa-fw mr-1"></i>' },
        ];
        // Experimental Feature
        if (_.isAciUser()) {
            categories.push({ displayText: `preferences`, click: () => this.showPreferences(map), icon: `<i class="fa-solid fa-flask fa-fw mr-1"></i>` });
        }
        await Loading.waitForPromises(Translate.get(categories.map(({ displayText }) => displayText))).then(t => {
            categories.map(c => c.displayText = t[c.displayText]);
        });
        const inputs = categories.map(category => {
            const $link = this.genDropdownLink(category);
            const $c = $link.find('[type=checkbox]');
            let toggle = false;
            let toggleAction = async () => {
                category.click.call(this);
                $c.prop('checked', toggle);
                toggle = !toggle;
            };
            $link.on('click', () => { toggleAction().catch(AError.handle); });
            $c.on('change', () => { toggleAction().catch(AError.handle); });
            return $link;
        });
        // const hideMapLabels = preferenceService.get(APrefs.HIDE_MAP_LABELS)
        // if (hideMapLabels) {
        //   // this.toggleMapLabels(map)
        // }
        // const mapPreferences: AMapPreferences = lockPreferences ?? {
        //   mapStyle: Number(preferenceService.get(APrefs.MAP_STYLE)),
        //   labelDensity: preferenceService.get(APrefs.MAP_LABEL_DENSITY),
        //   landmarkDensity: preferenceService.get(APrefs.MAP_LANDMARK_DENSITY),
        // }
        this.applyMapPreferences(map);
        menuService.addMapDropdown(inputs, {
            map,
            mapElement,
            icon: 'fa-solid fa-bars',
            order,
            position: MAP_POSITION.TOP_LEFT
        });
    }
    getGeoObjectColor(mapOption, geoObject) {
        const defaultColorConf = { strokeColor: '#880000', strokeOpacity: 1.0, fillColor: '#FF0000', fillOpacity: 0.5 };
        const geoType = this.mapOptionToGeoType(mapOption);
        switch (geoType) {
            case 'ParkingSpace':
                defaultColorConf.fillOpacity = 0.5;
                defaultColorConf.strokeOpacity = 1.0;
                // colorConfig = AMapHelperService.getColorConfig(geoType, defaultColorConf, geoObject?.Name)
                /// TODO: Jaap 2022-12-15 : this is Oslo specific so I must move it to server using BO.Color
                // switch (geoObject.Name) {
                //   case "Tax parking": colorConfig.fillColor = "#FFFF00"; colorConfig.strokeColor = "#888800"; break; case "Free parking": colorConfig.fillColor = "#99DD99"; colorConfig.strokeColor = "#000000"; break; case "Private Tax parking": colorConfig.fillColor = "#99DD99"; colorConfig.strokeColor = "#000000"; break; case "Motorcycle Parking": colorConfig.fillColor = "#00FF00"; colorConfig.strokeColor = "#008800"; break; case "Electric": colorConfig.fillColor = "#00FF00"; colorConfig.strokeColor = "#008800"; break;
                // }
                break;
            case 'RouteArea':
            case 'Zone':
            case 'TemporaryZone':
                defaultColorConf.fillOpacity = 0.4;
                defaultColorConf.strokeOpacity = 0.6;
                break;
            case 'ParkingMachine':
            case 'ImageLocation':
                defaultColorConf.fillOpacity = 0.8;
                defaultColorConf.strokeOpacity = 1.0;
                break;
            case 'Area':
                defaultColorConf.fillColor = '#FFFFAA';
                defaultColorConf.fillOpacity = 0.5;
                defaultColorConf.strokeOpacity = 0.3;
                break;
            case 'Segment':
                defaultColorConf.fillColor = '#888888';
                defaultColorConf.strokeColor = '#000000';
                defaultColorConf.fillOpacity = 0.2;
                defaultColorConf.strokeOpacity = 0.3;
                break;
            default:
                break;
        }
        const colorConfig = AMapHelperService.getColorConfig(geoType, defaultColorConf, geoObject?.Name);
        // TODO: Find out what to use for coloring
        if (geoObject.Attributes["KLEUR"]) {
            const hexColor = geoObject.Attributes["KLEUR"];
            colorConfig.fillColor = hexColor;
            colorConfig.strokeColor = new AColor(hexColor).hsv.lerpTo(new AColor('#000000').hsv, 0.5).hexi;
        }
        if (geoObject.Attributes["BO.Color"]) {
            const bo_color = this.getLegendColor(geoObject.Attributes["BO.Color"], true);
            if (bo_color) {
                colorConfig.fillColor = bo_color.fill;
                colorConfig.strokeColor = bo_color.stroke;
            }
        }
        return colorConfig;
    }
    getGeoObjectZIndex(mapOption) {
        switch (mapOption) {
            case MAP_OPTIONS.Region: return .1;
            case MAP_OPTIONS.Area: return .2;
            case MAP_OPTIONS.RouteArea: return .3;
            case MAP_OPTIONS.Segment: return .4;
            case MAP_OPTIONS.Zone: return .5;
            case MAP_OPTIONS.TemporaryZone: return .55;
            case MAP_OPTIONS.ParkingSpace: return .6;
            case MAP_OPTIONS.GeneratedParkingSpace: return .7;
            case MAP_OPTIONS.SplitParkingSpace: return .8;
            case MAP_OPTIONS.DirectedWaySegment: return .9;
            case MAP_OPTIONS.WaySegment: return 1.0;
            case MAP_OPTIONS.RouteOption: return 1.1;
            case MAP_OPTIONS.RouteIntersection: return 1.2;
            case MAP_OPTIONS.Address: return 1.3;
            case MAP_OPTIONS.ParkingMachine: return 1.4;
        }
    }
    /**
     * Toggles all polygon scales off except for the one given as argument
     * @param geoType
     */
    async toggleTo(map, geoType, click) {
        await Promise.all([
            this.toggle(map, geoType, click, true),
            ...Object.keys(map._geoObjectsVisible).map((key) => {
                const geoType = key;
                return (map._geoObjectsVisible[geoType] === true) ? this.toggle(map, geoType, click, false) : Promise.resolve();
            }),
        ]);
    }
    async toggle(map, input, clickEvent, visible) {
        try {
            // const isGeoType = ALL_GEO_TYPES.includes(input)
            const geoType = (typeof input === 'number') ? this.mapOptionToGeoType(input) : input;
            const mapOption = this.geoTypeToMapOption(geoType);
            await this.load(mapOption);
            if (visible) {
                await this.showGeoObjectsOnMap.call(this, geoType, map, PageScript.bounds || DefaultBounds(), clickEvent);
            }
            else {
                const geoInstances = this.getGeoInstancesOnMap(map, geoType);
                // const geoInstances = Object.values(this.cache[geoType] ?? [])
                await asyncMapArray(geoInstances, 20, (geoInstance) => geoInstance.setOptions({ visible }));
            }
            // TODO: Move geoObjectsVisible to map instances
            if (!map._geoObjectsVisible) {
                throw new Error(`AMap._geoObjectsVisible is not defined!`);
            }
            map._geoObjectsVisible[geoType] = visible !== undefined ? visible : !map._geoObjectsVisible[geoType];
            AEngine.warn(`Setting %p${geoType}%c Visible:`, map._geoObjectsVisible[geoType]);
            // await Promise.all(mapOptions.map(async (opt) => {
            //   const geoType = this.mapOptionToGeoType(opt)
            //   PageScript.geoObjectsVisible[geoType] = visible !== undefined ? visible : !PageScript.geoObjectsVisible[geoType]
            //   await asyncMapArray(Object.values(this.cache[geoType]!), 100, (geoInstance: AMapOptionTypes) => {
            //     geoInstance.setOptions({ visible })
            //   })
            // }))
        }
        catch (err) {
            AError.handle(err);
        }
    }
    /**
     * Whether the Geotype is loaded
     * @param geoType
     */
    isLoaded(geoType) {
        // TODO: Check whether the Object.keys().length > 0 is needed here
        return this.requested[geoType] === true && this.geoObjects[geoType] && Object.keys(this.geoObjects[geoType]).length > 0;
    }
    /**
     * Whether the GeoType is loading
     * @param geoType
     * @returns
     */
    isLoading(geoType) {
        return this.requested[geoType] instanceof Promise;
    }
    requestGeoObjects(geoType) {
        if (this.isLoaded(geoType)) {
            return Promise.resolve();
        }
        if (this.isLoading(geoType)) {
            return this.requested[geoType];
        }
        AEngine.log(`Fetching GeoData [%c${geoType}%n]`);
        let requestData = {};
        requestData[geoType] = null;
        this.requested[geoType] = Loading.waitForEvent(`GeoResponse_${geoType}`, true);
        requestService.send('GeoRequest', requestData);
        return this.requested[geoType];
    }
    /**
     * Load GeoObjects into memory
     * @param bitmask
     * @returns
     */
    load(bitmask) {
        if (bitmask === undefined)
            return Promise.reject(new Error(`No option selected for AMapHelperService.load`));
        let promises = [];
        for (let option of ALL_MAP_OPTIONS) {
            if (option & bitmask) {
                const geoType = this.mapOptionToGeoType(option);
                promises.push(this.requestGeoObjects(geoType));
            }
        }
        return Loading.waitForPromises(promises);
    }
    prepareMapItems(bitmask, options = {}) {
        if (bitmask & MAP_OPTIONS.Default && _.isAciUser() && !AEngine.isDevelopmentMode) {
            bitmask = MAP_OPTIONS.All;
        }
        const { showSearch = false, showOneScale = false, createToggleItems = true, allowExport = true, showLegend = false, fitPolygons = false, click, map, showOnLoad, createLabel, skipFit, lockPreferences } = options || {};
        return Loading.waitForPromises(this._prepareMapItems(bitmask, {
            showSearch,
            showOneScale,
            createToggleItems,
            allowExport,
            showLegend,
            fitPolygons,
            click,
            showOnLoad,
            createLabel,
            skipFit,
            lockPreferences,
            map
        }));
    }
    async _prepareMapItems(bitmask, options) {
        const { createToggleItems, showLegend, allowExport, showOneScale, click, fitPolygons, createLabel, skipFit, showSearch, lockPreferences } = options;
        const map = options.map || PageScript.map;
        const mapElement = map.getDiv();
        const $map = $(mapElement);
        map.set('lockPreferences', lockPreferences ?? {});
        let availableBitmask = 0;
        AEngine.log("Checking avalaible geo types");
        requestService.send('GeoRequest', {});
        let timeStamps = await Loading.waitForEvent("GeoDataTimeStamps", true);
        for (let type in timeStamps) {
            if (timeStamps[type] != null) {
                availableBitmask |= this.geoTypeToMapOption(type);
            }
        }
        AEngine.log("Avalaible geo types found");
        bitmask &= availableBitmask;
        const output = await Loading.waitForPromises([
            Promise.resolve().then(() => this.mapOverlayService.addGradientOverlay(map, mapElement)),
            // Map Hamburger menu
            this.createMapDropdown({ order: 1, map, mapElement }),
            // Map Scale toggle menu
            (createToggleItems === true) ? this.createMapToggleSettings(bitmask, { order: 2, showOneScale, click, fitPolygons, map }) : Promise.resolve(),
            (showSearch) ? menuService.addMapButton({
                map,
                mapElement,
                order: 4,
                icon: 'fa-solid fa-magnifying-glass',
                position: MAP_POSITION.TOP_LEFT,
            }).then($btn => {
                const $fa = $btn.find('i');
                $btn.on('click', () => {
                    const v = toggleMapSearch({
                        ele: map.getDiv(),
                        map: map
                    });
                    $fa.toggleClass('fa-magnifying-glass', !v);
                    $fa.toggleClass('fa-xmark', v);
                });
            }) : Promise.resolve(),
            menuService.addMapButtonRadio({
                map,
                mapElement,
                order: 99,
                titles: ['Map', 'Sattelite'],
                icons: ['fa-regular fa-leaf-maple', 'fa-solid fa-satellite-dish'],
                position: MAP_POSITION.TOP_RIGHT
            }).then(([$map, $sattelite]) => {
                $map.on('click', _ => this.setMapType(map, google.maps.MapTypeId.ROADMAP));
                $sattelite.on('click', _ => this.setMapType(map, google.maps.MapTypeId.HYBRID));
            }),
            // Map Fullscreen button
            menuService.addMapButton({
                map,
                mapElement,
                order: 100,
                icon: 'fa-solid fa-expand',
                position: MAP_POSITION.TOP_RIGHT
            }).then($btn => {
                $btn.on('click', _ => toggleMapFullScreen({ mapElement }));
                const $fa = $btn.find('i');
                Events.on(EVENTS.TOGGLE_FULLSCREEN, (v) => {
                    $fa.toggleClass('fa-compress', v);
                    $fa.toggleClass('fa-expand', !v);
                });
            }),
            // Show legend if enabled
            (showLegend === true) ? this.setDetectionsLegend(map, mapElement) : Promise.resolve(),
            // Show export if enabled
            (allowExport) ? this.createDownloadButton({ order: 10, map, mapElement }) : Promise.resolve(),
            // (map.streetViewControl === true) ? this.createButton({ order: 11, map, mapElement }).then(($btn) => {
            //   $map.addClass('override-streetview-btn')
            //   const waitForElement = async (getter: () => JQuery) => {
            //       const startTime = performance.now()
            //       let diff: number = 0
            //       let obj: any = null
            //       do {
            //         obj = getter()
            //         await sleep(80)
            //         diff = performance.now() - startTime
            //       } while (obj.length === 0 && diff <= 5000)
            //       AEngine.log('waitForElement diff =', diff)
            //       if (obj === null) {
            //         throw new Error(`waitForElement couldn't failed!`)
            //       }
            //       return obj
            //   }
            //   map.onLoad(async () => {
            //     const $btnPegman: JQuery = await waitForElement(() => $(mapElement).find('.gm-svpc'))
            //     // $btnPegman.appendTo($btn)
            //   })
            // }) : Promise.resolve(),
            // Create detection count at the bottom of the map
            (createLabel === true) ? this.createCountLabel() : Promise.resolve(),
            // Change map bounds to fit the contents
            (skipFit !== true) ? Promise.resolve().then(_ => map?.fit()) : Promise.resolve(),
            // Create safe-zone for streetview btn to appear
            (map?.streetViewControlOptions?.position === 7) ? Promise.resolve()
                .then(_ => AEngine.get(AMapOverlayService).setOffset(map, mapElement, MAP_POSITION.TOP_RIGHT, '0')) : Promise.resolve()
        ]);
        // Initialize resizing events
        if (!$map.is('.map-sm,.map-xs')) {
            const onSizeChange = () => {
                // const $dragParent = $map.closest('.aci-draggable-view').css('min-width', '245px')
                const collisionData = this.mapOverlayService.isCollisionBetween(map, mapElement, MAP_POSITION.TOP_LEFT, MAP_POSITION.TOP_RIGHT, { checkX: true });
                if (collisionData.isCollision && !$map.is('.map-xs')) {
                    const $overlay = this.mapOverlayService.getOrCreateOverlay(map, mapElement, MAP_POSITION.TOP_LEFT);
                    $overlay.css('width', $overlay.width());
                    $map.addClass('map-xs');
                }
                if (!collisionData.isCollision && $map.is('.map-xs')) {
                    const $overlay = this.mapOverlayService.getOrCreateOverlay(map, mapElement, MAP_POSITION.TOP_LEFT);
                    $overlay.css('width', '');
                    $map.removeClass('map-xs');
                }
                const mapWidth = $map.width();
                const isSmall = mapWidth < 600 && !collisionData.isCollision;
                $map.toggleClass('map-sm', isSmall);
                const isMedium = mapWidth >= 600 && mapWidth <= 900 && !collisionData.isCollision;
                $map.toggleClass('map-md', isMedium);
            };
            google.maps.event.addListener(map, 'resize', onSizeChange);
            Events.on(EVENTS.CONTENT_DRAG, onSizeChange);
        }
        return output;
    }
    /**
     * Changes the color of the marker
     * @param marker
     * @param options
     */
    changeColorMarker(marker, options) {
        marker.setOptions(options);
    }
    /**
     * @deprecated
     * Reverts polygon colors & opacity to the initial colors
     * @param bitmask
     */
    revertColors(bitmask = MAP_OPTIONS.All) {
        ALL_MAP_OPTIONS.filter(opt => (opt & bitmask)).map((opt) => {
            const geoType = this.mapOptionToGeoType(opt);
            if (this.cache[geoType]?.length) {
                this.cache[geoType].map((marker) => {
                    const { strokeColor, strokeOpacity, fillColor, fillOpacity } = marker.data;
                    marker.setOptions({
                        strokeColor, strokeOpacity, fillColor, fillOpacity
                    });
                });
            }
        });
    }
    /**
     * Finds the markers that are saved to the PageScript object
     */
    fetchMarkers() {
        return PageScript.Markers || PageScript.markers || [];
    }
    /**
     * Deletes polygons (zones, areas, parking spaces or markers) from the map
     * @param arrayOrObject
     */
    destroy(arrayOrObject) {
        const eventsToClear = ['click', 'rightclick'];
        const array = Array.isArray(arrayOrObject) ? arrayOrObject : convertObjectToArray(arrayOrObject);
        for (let index in array) {
            for (const key of eventsToClear) {
                google.maps.event.clearListeners(array[index], key);
            }
            array[index].setMap(null);
            delete array[index];
        }
        if (array.length) {
            array.length = 0;
        }
    }
    /**
     * Unloads polygons (zones, areas, parking spaces or markers) from the map
     * @param arrayOrObject polygons on the map
     * @param options type of unload
     */
    unload(arrayOrObject, options = UNLOAD_OPTIONS.AllListeners) {
        const eventsToClear = [
            'bounds_changed',
            'center_changed',
            'click',
            'dblclick',
            'drag',
            'dragend',
            'dragstart',
            'heading_changed',
            'idle',
            'maptypeid_changed',
            'mousemove',
            'mouseout',
            'mouseover',
            'projection_changed',
            'resize',
            'rightclick',
            'tilesloaded',
            'tilt_changed',
            'zoom_changed',
        ];
        const array = Array.isArray(arrayOrObject) ? arrayOrObject : convertObjectToArray(arrayOrObject);
        switch (options) {
            case UNLOAD_OPTIONS.Default:
                for (let index in array) {
                    for (const key of eventsToClear) {
                        google.maps.event.clearListeners(array[index], key);
                    }
                    array[index].setMap(null);
                }
                break;
            case UNLOAD_OPTIONS.AllListeners:
                for (let index in array) {
                    for (const key of eventsToClear) {
                        google.maps.event.clearListeners(array[index], key);
                    }
                    array[index].setMap(null);
                }
                break;
            case UNLOAD_OPTIONS.None:
                for (let index in array) {
                    array[index].setMap(null);
                }
                break;
            default:
                throw new Error(`MapHelperService.unload(... , ${options}) contains unknown options.`);
        }
    }
    lerp(from, to, t) {
        const sphericalGeometry = google.maps.geometry.spherical;
        const heading = sphericalGeometry.computeHeading(from, to);
        const distanceToTarget = sphericalGeometry.computeDistanceBetween(from, to);
        const pos = sphericalGeometry.computeOffset(from, distanceToTarget * t, heading);
        const mag = sphericalGeometry.computeDistanceBetween(from, pos);
        return { pos, mag };
    }
    clamp01(v) {
        if (v > 1.0) {
            return 1.0;
        }
        else if (v < 0.0) {
            return 0.0;
        }
        return v;
    }
    /**
     * @deprecated
     * @param detectionData
     * @returns
     */
    getColorLegacy(detectionData) {
        let FillColor = '';
        let StrokeColor = '';
        const set = (f, o) => {
            if (f !== null && FillColor === '')
                FillColor = f;
            if (o !== null && StrokeColor === '')
                StrokeColor = o;
        };
        let { IsIllegallyParked, HasParkingRight } = detectionData;
        if (HasParkingRight === null) {
            const { fill, stroke } = this.getLegendColor('grey', false); // AConfig.get('drawing & colors.detections.unknown')
            set(fill, stroke);
        }
        if (HasParkingRight === 0) {
            const { fill, stroke } = this.getLegendColor('red', false); // AConfig.get('drawing & colors.detections.noParkingRight')
            set(fill, stroke);
        }
        if (IsIllegallyParked) {
            const { stroke } = this.get_legend_brown_outline(); // AConfig.get('drawing & colors.detections.illegallyParked')
            set(null, stroke);
        }
        if (FillColor === null) {
            const { fill, stroke } = this.getLegendColor('green', false); // AConfig.get('drawing & colors.detections.default')
            set(fill, stroke);
        }
        return { FillColor, StrokeColor };
    }
    /**
     * Gets the bounds of the map
     */
    getMapBounds(map, expandInMeters) {
        if (!map)
            return undefined;
        var output = { north: 0, east: 0, south: 0, west: 0 };
        try {
            const bounds = map.getBounds() ?? DefaultBounds();
            if (expandInMeters !== undefined) {
                // north & south = lat
                // west & east = lng
                bounds.extend(AGeoUtils.transformPoint(bounds.getNorthEast().toJSON(), 45, expandInMeters));
                bounds.extend(AGeoUtils.transformPoint(bounds.getSouthWest().toJSON(), 230, expandInMeters));
            }
            output = bounds.toJSON();
        }
        catch (err) {
            console.error(err);
            output = DefaultBounds().toJSON();
        }
        return output;
    }
    /**
     * Gets points of a Polygon or Marker
     * @param marker Polygon or Marker
     */
    getPoints(marker) {
        if (marker == null) {
            throw new Error(`AMapHelperService.getPoints unexpected marker is not defined!`);
        }
        const path = marker.getPath();
        const length = path.getLength();
        const lnglat = [];
        for (let i = 0; i < length; i++) {
            const { lat, lng } = path.getAt(i).toJSON();
            lnglat.push([lng, lat]);
        }
        return lnglat;
    }
    calcBoundsByLargestScale(map) {
        var bounds = new google.maps.LatLngBounds();
        const _geoObjectsVisible = map?._geoObjectsVisible ?? PageScript.geoObjectsVisible;
        for (let geoType of ALL_GEO_TYPES) {
            if (_geoObjectsVisible[geoType]) {
                this.cache[geoType].map(polygon => {
                    if (polygon.getVisible()) {
                        bounds.extend(getCenterAny(polygon));
                    }
                });
            }
        }
        if (PageScript.RouteList) {
            for (const routeItem of PageScript.RouteList) {
                const path = routeItem.getPath();
                path.getArray().map(pos => bounds.extend(pos));
            }
        }
        return bounds;
    }
    /**
     * Gets the bounds of a polygon
     * @param {any} polygon
     */
    getPolygonBounds(polygon) {
        let paths = polygon.getPaths();
        let bounds = new google.maps.LatLngBounds();
        paths.forEach(function (path) {
            let array = path.getArray();
            for (let i = 0, l = array.length; i < l; i++) {
                bounds.extend(array[i]);
            }
        });
        return bounds;
    }
    getMapIcon(nodeType, defaultValue = null) {
        switch (nodeType) {
            case "ExternalDevice": return '/img/huisstijl/pda_los_72ppi_rgb.png';
            case "Pda": return '/img/huisstijl/pda_los_72ppi_rgb.png';
            case "ScanAuto": return '/img/huisstijl/scanacar_los_72ppi_rgb.png';
            case "ScanScooter": return '/img/huisstijl/scooter_los_72ppi_rgb.png';
            case "ScanSegway": return '/img/huisstijl/segway_los_72ppi_rgb.png';
            case "ScanBike": return '/img/huisstijl/fiets_los_72ppi_rgb.png';
            case "BackOffice": return '/img/huisstijl/centrale_los_72ppi_rgb.png';
            case "CentralVerification": return '/img/huisstijl/centrale_los_72ppi_rgb.png';
            case "ScanCam": return '/img/huisstijl/paal_72ppi_rgb.png';
            default: return defaultValue;
        }
    }
    /**
     * Returns flat array of all zones
     */
    getZones() {
        const geoType = this.mapOptionToGeoType(MAP_OPTIONS.Zone);
        const output = [];
        Object.keys(this.geoObjects[geoType]).map(zoneId => {
            const zone = this.geoObjects[geoType][zoneId];
            output.push(Object.assign({ Area: null }, zone));
        });
        return output;
    }
    /**
     * Returns all the areas sorted by areaname
     */
    getAreaPairs() {
        const geoType = this.mapOptionToGeoType(MAP_OPTIONS.Area);
        return Object.keys(this.geoObjects[geoType]).map((key) => {
            const { Index, Name } = this.geoObjects[geoType][key];
            return {
                Index: Index,
                Name: Name
            };
        }).sort((a, b) => a.Name.localeCompare(b.Name));
    }
    /**
     * @deprecated
     */
    static getColorConfig(polygonType, defaultColorConfig, polygonName) {
        // TODO: Remove AMapHelperService.getColorConfig
        // if (AEngine.isDevelopmentMode) {
        //   AEngine.warn(`// TODO: Remove AMapHelperService.getColorConfig`)
        // }
        let colorConfig = { strokeColor: '#000000', strokeOpacity: 1., fillColor: '#FFFFFF', fillOpacity: .5 };
        if (defaultColorConfig) {
            for (var key in colorConfig) {
                if (defaultColorConfig[key])
                    colorConfig[key] = defaultColorConfig[key];
            }
        }
        let configEntryName = polygonType + 'Color';
        if (Config.BackOfficeConfig && Config.BackOfficeConfig[configEntryName]) {
            let colorConfigTemp = {};
            let configEntry = Config.BackOfficeConfig[configEntryName];
            if (polygonName && configEntry.Names && configEntry.Names[polygonName]) {
                colorConfigTemp = configEntry.Names[polygonName];
            }
            else if (configEntry.Default) {
                colorConfigTemp = configEntry.Default;
            }
            for (var key in colorConfig) {
                if (colorConfigTemp[key])
                    colorConfig[key] = colorConfigTemp[key];
            }
        }
        return colorConfig;
    }
}
