import { ACrypto } from "../classes/ACrypto.js";
export class AUploadedImageOrm {
    async fetchAll() {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: ( /*sql*/`
        SELECT
          ImageId,
          SmallImage AS SmallImage,
          AltText,
          MimeType,
          FileSize,
          CreatedAt
        FROM uploaded_images
      `),
            Language: Language
        });
        return rows;
    }
    async fetch(options) {
        if (options.ImageIds.length === 0) {
            return [];
        }
        const params = {};
        options.ImageIds.map((id, i) => params[`ImageId${i}`] = id);
        const conditions = options.ImageIds.map((id, i) => `ImageId=:ImageId${i}`);
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: ( /*sql*/`
        SELECT
          ImageId,
          LargeImage AS LargeImage,
          MediumImage AS MediumImage,
          SmallImage AS SmallImage,
          AltText,
          MimeType,
          FileSize,
          CreatedAt
        FROM uploaded_images
        WHERE ${conditions.join(' OR ') || 'true'}
      `),
            Params: params,
            Language: Language
        });
        return rows.toArray();
    }
    async create(options) {
        await requestService.query({
            Query: ( /*sql*/`
        INSERT INTO uploaded_images (ImageId, LargeImage, MediumImage, SmallImage, AltText, FileSize, MimeType)
        VALUES (:ImageId, FROM_BASE64(:LargeImage), FROM_BASE64(:MediumImage), FROM_BASE64(:SmallImage), :AltText, :FileSize, :MimeType)
      `),
            // ON DUPLICATE KEY UPDATE LargeImage=:LargeImage, MediumImage=:MediumImage, SmallImage=:SmallImage, AltText=:AltText
            Params: {
                ImageId: options.ImageId ?? ACrypto.uuid7String(),
                LargeImage: options.LargeImage,
                MediumImage: options.MediumImage,
                SmallImage: options.SmallImage,
                AltText: options.AltText,
                FileSize: options.FileSize,
                MimeType: options.MimeType,
                // CreatedByUser: _.getUser().User,
                // CreatedByDisplayName: _.getUser().UserDisplayName,
            }
        });
        return true;
    }
}
