export class APopoverService {
    get $container() { return this.$popoverParent; }
    constructor() {
        this.maxMenuItemsShown = 1;
        this.openDelay = 60;
        this.closeDelay = 200;
        this.$sidebar = $('.sidebar-menu');
        this.$menuItems = $('.sidebar-menu li a, .sidebar-menu li');
        this.$popoverParent = this.genContainer();
        this.popovers = {};
        this.history = [];
        this.zoffset = 0;
        this.closeAllTimeoutId = -1;
    }
    getNextIndexZ() {
        return 1001 + this.zoffset++;
    }
    genContainer() {
        const $container = $('<div class="popover-container custom-scroll"></div>');
        $('body').append($container);
        return $container;
    }
    setVisible($p, visible) {
        $p.toggleClass('popover-open', visible);
        $p.css('z-index', visible ? this.getNextIndexZ() : '');
    }
    async updateHoverState(nextState) {
        nextState();
        const shouldClosePopovers = !this.mouseOnSideMenu && !this.mouseOnPopOver;
        if (shouldClosePopovers) {
            if (this.closeAllTimeoutId === -1) {
                this.closeAllTimeoutId = setTimeout(() => {
                    this.closeAllTimeoutId = -1;
                    this.zoffset = 0;
                    // console.log('Closing all popovers')
                    this.removePopoverExcept(-1);
                }, this.closeDelay);
            }
        }
        else if (this.closeAllTimeoutId !== -1) {
            clearTimeout(this.closeAllTimeoutId);
            this.closeAllTimeoutId = -1;
        }
    }
    initMenuPopovers() {
        // When menu is closed, the icons are not clickable. 
        // To Fix this we create a click proxy for when the menu is closed.
        const $lis = $('.sidebar-menu .menu-links > li');
        $lis.on('click', e => {
            const $t = $(e.target);
            if ($t.closest('.menu-open').length === 0 && !$t.is('a')) {
                const $li = $t.is('li') ? $t : $t.closest('li');
                const $a = $li.find('a');
                const href = $a.attr('href');
                window.location.href = href || '';
            }
        });
        // Add menu-dropdown class
        this.$menuItems.each((i, ele) => {
            const $menuItem = $(ele).closest('li');
            const $next = $menuItem.next();
            const isDropDown = ($next.is('[ref]'));
            if (isDropDown) {
                $menuItem.attr('uid', $next.find('[href]').eq(0).attr('href') || '');
                $menuItem.addClass('menu-dropdown');
            }
        });
        this.$menuItems.on('mouseenter', async (e) => {
            const $targetLi = $(e.target).closest('li');
            const uid = $targetLi.attr('uid');
            const pData = this.getOrCreatePopover(uid, $targetLi);
            pData.$popover.css('margin-top', '-22px');
            if (pData.openTimeoutId !== -1) {
                // Dont register multiple events
                return;
            }
            pData.openTimeoutId = setTimeout(async () => {
                pData.openTimeoutId = -1;
                this.restore(uid);
                // console.log('open', uid)
                const $next = $targetLi.next();
                if (!$next.is('[ref="menu"]')) {
                    return;
                }
                const $popover = pData.$popover;
                this.setVisible($popover, true);
                const $hrefs = $next.find('a');
                const lis = await Promise.all($hrefs.toArray().map(async (a) => {
                    const $a = $(a);
                    const href = $a.attr('href') || '';
                    const item = menuService.menuFlat[href];
                    const isObsolete = (item && item.obsolete === true);
                    const additionalHtml = (isObsolete) ? `<div class="menu-obsolete no-select">${await Translate.get(`Needs update`)}</div>` : '';
                    const liAttr = menuService.isCurrentPage(href) ? `class="active"` : '';
                    return ( /*html*/`<li ${liAttr}><a href="#!${href}"><span>${$a.text()}${additionalHtml}</span></a></li>`);
                }));
                $popover.find('ul').html(`${lis.join('')}`);
                this.resizePopover({ $li: $targetLi, $popover });
            }, this.openDelay);
        });
        this.$menuItems.on('mouseleave', async (e) => {
            const $targetLi = $(e.target).closest('li');
            const uid = $targetLi.attr('uid');
            const pData = this.popovers[uid];
            if (pData.openTimeoutId !== -1) {
                // console.log('stopped open timeout', uid)
                clearTimeout(pData.openTimeoutId);
                pData.openTimeoutId = -1;
            }
        });
        $(document).on('mouseenter', '.sidebar-popover.popover-menu[uid]', (e) => {
            // Revive potentially closing popover window
            const $t = $(e.target);
            // console.log($t)
            const uid = ($t.is('[uid]') ? $t : $t.closest('[uid]')).attr('uid');
            this.updateHoverState(() => this.mouseOnPopOver = true); // enter
            this.restore(uid);
        });
        $(document).on('mouseleave', '.sidebar-popover.popover-menu[uid]', (e) => {
            this.updateHoverState(() => this.mouseOnPopOver = false); // exit
        });
        $(document).on('mouseenter', '.sidebar-menu', (e) => {
            this.updateHoverState(() => this.mouseOnSideMenu = true); // enter
        });
        $(document).on('mouseleave', '.sidebar-menu', (e) => {
            this.updateHoverState(() => this.mouseOnSideMenu = false); // exit
        });
        $(document).on('click', (e) => {
            const $clicked = $(e.target);
            const isSidebar = $clicked.is('.sidebar-menu');
            const isNotSidebarChild = $clicked.closest('.sidebar-menu').length === 0;
            if (isSidebar || isNotSidebarChild) {
                Object.values(this.popovers).map(v => v.$popover).map($popover => {
                    // $popover.removeClass('popover-open')
                    this.setVisible($popover, false);
                });
            }
        });
    }
    resizePopover(opt) {
        const { $li, $popover, $relativeParent = $('body') } = opt;
        let { top } = $li.offset() || { top: 0 };
        const width = this.$sidebar.width();
        const bodyHeight = $relativeParent.height() || 0;
        const popoverHeight = $popover.height() || 0;
        if (top + popoverHeight > bodyHeight) {
            top = bodyHeight - popoverHeight;
        }
        $popover.css('left', width + 'px');
        $popover.css('top', top + 'px');
    }
    getOrCreatePopover(uid, $li) {
        let $popover;
        if (this.popovers.hasOwnProperty(uid)) {
            const pData = this.popovers[uid];
            $popover = pData.$popover;
        }
        else {
            const template = ( /*html*/`
        <div uid="${uid}" class="sidebar-popover popover-menu">
          <div class="popover-title">${$li.find('a[href]').text().trim()}</div>
          <ul>
            <li><a href="#"></a></li>
            <li><a href="#"></a></li>
            <li><a href="#"></a></li>
          </ul>
        </div>
      `);
            $popover = $(template);
            this.$popoverParent.append($popover);
            this.popovers[uid] = { $li, $popover, openTimeoutId: -1, closeTimeoutId: -1 };
        }
        if (this.history.includes(uid)) {
            this.history.splice(this.history.indexOf(uid), 1);
        }
        this.history.push(uid);
        if (this.history.length > this.maxMenuItemsShown) {
            this.removePopover(this.history[0]);
        }
        return this.popovers[uid];
    }
    removePopoverExcept(uidToIgnore) {
        this.history.filter(uid => uid !== uidToIgnore).map(uid => this.removePopover(uid));
    }
    async removePopover(uid) {
        const index = this.history.indexOf(uid);
        if (index === -1) {
            return;
        }
        this.history.splice(index, 1);
        const pData = this.popovers[uid];
        if (pData.closeTimeoutId !== -1) {
            // Dont register multiple events
            return;
        }
        pData.closeTimeoutId = setTimeout(() => {
            pData.closeTimeoutId = -1;
            this.setVisible(pData.$popover, false);
        }, this.closeDelay);
    }
    restore(uid) {
        const pData = this.popovers[uid];
        if (pData.closeTimeoutId !== -1) {
            // console.log('restored', uid)
            clearTimeout(pData.closeTimeoutId);
            pData.closeTimeoutId = -1;
            if (this.history.includes(uid)) {
                this.history.splice(this.history.indexOf(uid), 1);
            }
            this.history.push(uid);
            this.setVisible(pData.$popover, true);
        }
    }
}
