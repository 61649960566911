import { AError } from "../classes/AError.js";
import { AUserManualBasicWindow } from "../classes/usermanual/AUserManualBasicWindow.js";
import { AFormInstance } from "../core/form/AFormInstance.js";
import { AUploadedImageOrm } from "../orm/AUploadedImageOrm.js";
import { AUserManualOrm } from "../orm/AUserManualOrm.js";
import { AUrlEncodedImageFromBase64 } from "../utils/tools.js";
import { ALERT_BUTTONS, ALERT_STATUS } from "./AAlertService.js";
import { EVENTS } from "./AEventService.js";
export class AUserManualService {
    constructor() {
        this.orm = new AUserManualOrm();
        this.imageOrm = new AUploadedImageOrm();
        this.cachedImages = {};
    }
    get entries() {
        return this.cache.toArray();
    }
    async autoInit() {
        Events.h_once(EVENTS.PREFETCH, async () => {
            await this.reloadData();
        });
    }
    async prefetchImages(markdown) {
        var regex = /\{[ ]*IMG:[ ]?(.{36})[ ]?}/g;
        var result;
        let ImageIds = [];
        while ((result = regex.exec(markdown)) !== null) {
            ImageIds.push(result[1]);
        }
        await this.fetchImages(ImageIds);
    }
    async fetchImages(imgIds) {
        const ImageIds = imgIds.filter(v => !this.cachedImages.hasOwnProperty(v));
        const images = await this.imageOrm.fetch({ ImageIds: ImageIds });
        images.map(img => {
            this.cachedImages[img.ImageId] = img;
        });
    }
    async renderMarkdown(markdown) {
        var regex = /\{[ ]*IMG:[ ]?(.{36})[ ]?}/g;
        var result;
        while ((result = regex.exec(markdown)) !== null) {
            if (result !== null && result[1] != null) {
                const { LargeImage, AltText } = this.cachedImages[result[1]];
                markdown = markdown.replace(result[0], `![${AltText}](${AUrlEncodedImageFromBase64(LargeImage)})`);
            }
        }
        return await Promise.resolve().then(() => marked.parse(markdown));
    }
    async create(options) {
        const success = await this.orm.create(options);
        if (success) {
            await this.reloadData();
        }
        return success;
    }
    async unlink(options) {
        await this.orm.unlink(options);
        await this.reloadData();
    }
    async link(id_mr, markdownId) {
        await this.orm.link(id_mr, markdownId);
        await this.reloadData();
    }
    async reloadData() {
        this.cache = await this.orm.fetchAll();
    }
    async showUserManual() {
        const currentPageIdMr = routeService.meta.menuItem?.id_mr;
        const manual = this.entries.find(e => e.Id_Mr == currentPageIdMr);
        if (manual !== undefined) {
            const win = new AUserManualBasicWindow({ showTitle: false, manual });
            await win.show();
        }
    }
    async showLinkPopup(opt) {
        // const currentPageIdMr = opt?.Id_Mr || routeService.meta.menuItem?.id_mr
        // const manual = this.entries.find(e => e.Id_Mr == opt.Id_Mr) //?? this.entries.find(e => e.Id_Mr == currentPageIdMr)
        const form = new AFormInstance({
            ignoreWildcards: true,
            formInputs: [
                {
                    id: 'MarkdownId',
                    label: 'Markdown',
                    type: 'select',
                    options: this.entries.map(item => {
                        return {
                            id: item.MarkdownId,
                            text: item.Title,
                        };
                    })
                },
                {
                    id: 'Id_Mr',
                    label: 'Page',
                    type: 'select',
                    skipTranslate: true,
                    options: Object.keys(menuService.menuFlat).filter(k => menuService.menuFlat[k].id_mr != null).map(k => {
                        let { id_mr, url, title } = menuService.menuFlat[k];
                        if (this.entries.findIndex(v => v.Id_Mr == id_mr) !== -1) {
                            return null;
                        }
                        return {
                            id: id_mr,
                            text: `${url} (${id_mr})`
                        };
                    }).filter(v => v != null)
                }
            ]
        });
        const events = Alerts.show({
            translatedTitle: await Translate.get('No manual assigned!'),
            content: await Loading.waitForPromises(form.generate({ translate: true })),
            buttons: ALERT_BUTTONS.saveCancel,
        });
        await form.injectFormData({
            formData: { Id_Mr: opt.Id_Mr, MarkdownId: opt.MarkdownId }
        });
        await form.initFormValidation();
        return new Promise((resolve) => {
            events.on(ALERT_STATUS.ON_MODAL_CLOSED, async ({ action }) => {
                if (action !== ALERT_STATUS.ON_ACTION_PROCEED) {
                    return resolve(action);
                }
                try {
                    if (!form.validate()) {
                        Alerts.incomplete();
                        return false;
                    }
                    const data = form.extractFormData({ cleanData: true });
                    await this.link(data.Id_Mr, data.MarkdownId);
                    resolve(action);
                }
                catch (err) {
                    AError.handle(err);
                }
                finally {
                    // Loading.waitForPromises(this.refresh())
                }
            });
        });
    }
}
